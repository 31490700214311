import { Col, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const TitleText = styled.h3`
  font-weight: 600;
  font-size: 40px;
  color: ${(props) => props.color ?? "#0d97d6"};
  line-height: 45px;
  @media (max-width: 568px) {
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
  }
`;

export const SecondaryText = styled.p`
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? "400"};
  font-size: ${(props) => props.fontSize ?? "14px"};
  line-height: 18px;
  margin: 0px;
  color: ${(props) => props.color ?? "#000"};
`;

export const PrimaryText = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => props.fontSize ?? "25px"};
  color: ${(props) => props.color ?? " #74b750"};
  line-height: 28px;
`;

export const CustomLink = styled(NavLink)`
  color: ${(props) => props.currentnav ?? "#0D97D6"};
  text-decoration: none;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: ${(props) => props.color ?? "#74B750"};
  }
`;

export const LinkText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  margin: 0px;
  color: ${(props) => props.color ?? "inherit"};
`;

export const Button = styled.button`
  border: ${(props) => props.border ?? "1px solid #0d97d6"};
  box-sizing: border-box;
  border-radius: 8px;
  background: ${(props) => props.background ?? "none"};
  padding: ${(props) => props.padding ?? "0px 12px"};
  color: #0d97d6;
`;

export const Line = styled.span`
  display: block;
  width: 30px;
  height: 3px;
  background: black;
  margin-block: 10px;
  border-radius: 4px;
  transition: transform 0.25s;
  opacity: ${(props) => props.opacity ?? "0.25"};
  transform: ${(props) => props.transform ?? "translateY(0px) rotate(0deg)"};
`;

export const HeaderBackground = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  z-index: -1;
  position: absolute;
  @media (max-width: 768px) {
    background: linear-gradient(
      115.23deg,
      #0d97d6 -1.74%,
      #0d97d6 30.97%,
      #74b750 66.13%
    );
  }
`;

export const NavContainer = styled(Nav).attrs(() => ({
  className: "justify-content-around",
}))`
  width: 70%;
  @media (max-width: 1172px) {
    width: 75%;
  }
  @media (max-width: 991.11px) {
    position: absolute;
    top: 7px;
    background: #f1f1f1;
    z-index: 2;
    width: 100%;
    padding: 16px;
    border-radius: 0px 0px 8px 8px;
  }
`;

export const ArrowTipBox = styled.div`
  background: #fff;
  height: 240px;
  padding: 0px 16px;
  width: 240px;
  border-radius: 0px 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 8px solid rgba(96, 96, 96, 0.05);
  right: 0px;
  position: absolute;
  &:after {
    content: "";
    width: 40px;
    height: 26px;
    background: ${(props) => props.background ?? "#0d97d6"};
    position: absolute;
    top: 57.78px;
    left: -22.3px;
    transform: rotate(45deg);
    z-index: -2;
  }

  &:before {
    width: 235px;
    height: 82px;
    background: ${(props) =>
      props.gradient ??
      "linear-gradient(90deg, #60bbf8 -14.89%, #0d97d6 99.53%)"};
    position: absolute;
    content: " ${(props) => props.title ?? " "}";
    font-family: "Montserrat", sans-serif;
    top: -16px;
    left: -26px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    padding: 0px 16px 0px 26px;
    box-shadow: rgba(96, 96, 96, 0.05) 0px 0px 0px 8px;
  }
`;

export const FilledLine = styled.div`
  height: 18px;
  width: 100%;
  position: absolute;
  background: #dddedf;
  bottom: 5px;
  left: 0px;
  z-index: -3;
  border-radius: ${(props) => props.borderRadius ?? "0px"};
`;

export const Circle = styled.div`
  height: 30px;
  width: 30px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 30px;
  position: absolute;
  bottom: 0px;
  right: 40px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  &:after {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: ${(props) => props.background ?? "#0077ad"};
    position: absolute;
  }
`;

export const PointerDiv = styled.div`
  position: relative;
  margin-bottom: 12px;
  // padding: ${(props) => props.padding ?? "0px 0px 1rem 3rem"};
  // &:before {
  //   content: "";
  //   width: 3.5px;
  //   height: ${(props) => props.height ?? "100%"};
  //   border-radius: ${(props) => props.borderRadius ?? "0px"};
  //   background: #0d97d6;
  //   position: absolute;
  //   left: 0px;
  // }
`;

export const PricingBox = styled.div`
  height: ${(props) => props.height ?? "480px"};
  width: 30%;
  background: #f7f7f7;
  min-width: 300px;
  position: relative;
  z-index: 9;
  overflow: hidden;
  box-shadow: ${(props) =>
    props.boxShadow ??
    "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"};
  &:before {
    content: "";
    height: ${(props) => (props.height ? "350px" : "300px")};
    border-radius: 0px 0px 60% 60%;
    width: 100%;
    background: ${(props) => (props.height ? "#0D97D6" : "#f2f2f2")};
    border-radius: 50%;
    top: -128px;
    left: -25px;
    position: absolute;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    height: 12px;
    background: #497d2d;
    width: 100%;
  }
`;

export const PricingButton = styled.button`
  font-weight: bold;
  margin: auto 3em 1em 3em;
  padding: 0.5em 1em;
  color: #fff;
  border-radius: 24px;
  background: linear-gradient(135deg, #0d97d6, #0077ad);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.4s;

  &:hover,
  &:focus {
    background: linear-gradient(
      115.23deg,
      #0d97d6 -1.74%,
      #0077ad 30.97%,
      #0077ad 66.13%
    );
  }
`;

export const PricingSection = styled.div`
  border-radius: 12px 12px 0 0;
  background: #0d97d6;
  background: ${(props) =>
    props.background ?? "linear-gradient(135deg, #0077ad, #005b84)"};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 246px;
  flex-direction: column;
  height: 100%;
`;

export const PricingItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  flex: 0 1 330px;
`;

export const EllipsisText = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lineClamp ?? "4"};
  -webkit-box-orient: vertical;
  margin: 0px;
  font-size: ${(props) => props.fontSize ?? "14px"};
  color: ${(props) => props.color ?? "#000000"};
  font-weight: ${(props) => props.fontWeight ?? "normal"};
  text-transform: ${(props) => props.textTransform ?? "none"};
  line-height: ${(props) => props.lineHeight ?? "initial"};
`;

export const TestomonialContainer = styled.div`
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  @media (min-width: 768px) {
    min-width: 320px;
  }
`;

export const GradientBackground = styled.div`
  min-height: calc(100vh - 77px);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    background: linear-gradient(
      115.23deg,
      #0d97d6 -1.74%,
      #0d97d6 30.97%,
      #74b750 66.13%
    );
    padding: 16px 0px;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${(props) => props.background ?? "#fff"};
  border-radius: ${(props) => props.borderRadius ?? "4px"};
  padding: ${(props) => props.padding ?? "12px 20px"};
  margin-top: ${(props) => props.marginTop ?? "12px"};
`;

export const TextArea = styled.textarea`
  border-radius: 12px;
  border: 1px solid #000000;
  padding: 8px 16px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  resize: none;
`;

export const ErrorText = styled.p`
  font-size: 11px;
  margin: 0px;
  color: red;
`;

export const Input = styled.input`
  border: ${(props) => props.border ?? "1px solid #000000"};
  box-sizing: border-box;
  border-radius: ${(props) => props.borderRadius ?? "10px"};
  padding: 8px 16px;
  color: #000;
  width: ${(props) => props.width ?? "100%"};
  ${({ border }) =>
    border &&
    `margin-top:12px;
    border-bottom: 1px solid #000;
    padding:6px 4px;
    border-radius:0px
  `}
`;

export const ContactFomContainer = styled.div`
  width:${(props) => props.width ?? "100%"};
  display: flex;
  flex-direction: column;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
  border-radius: 23px;
  background: rgb(249, 249, 249);
  justify-content: ${(props) => props.justifyContent ?? "initial"};
  align-items:${(props) => props.alignItems ?? "initial"}
  box-sizing: border-box;
  padding: 2rem;
  z-index: 10;
  height:${(props) => props.height ?? "auto"}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  min-width: 290px;
  max-height: ${(props) => props.maxHeight ?? "100%"};
`;

export const ListItemCol = styled(Col).attrs(() => ({
  className: "mt-3",
}))`
  // > * {
  //   &:first-child {
  //     &:before {
  //       border-radius: 20px 20px 0px 0px;
  //     }
  //   }
  //   &:last-child {
  //     &:before {
  //       height: 17px;
  //     }
  //   }
  // }
`;

export const UlList = styled.li`
  line-height: 20px;
  font-weight: ${(props) => props.fontWeight ?? "400"};
  text-style: none;
  text-align: left;
`;

export const FooterText = styled.p`
  font-size: 16px;
  color: white;
  cursor: pointer;
  min-height: 25px;
`;

export const CopyRightText = styled.p`
  margin: 0px;
  color: white;
  line-height: 20px;
  display: inline-block;
  z-index: 9;
  background: #004c74;
  padding: 16px;
  width: 100%;
`;

export const FeaturesColumn = styled(Col)`
  background: #f1f1f1;
  minheight: 200px;
  border-radius: ${(props) =>
    props.rightImage ? "12px 0px 0px 12px" : "0px 12px 12px 0px"};
  @media (max-width: 568px) {
    border-radius: 0px 0px 12px 12px;
  }
`;
