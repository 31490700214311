import { Header } from "./HeaderSection";
import girlsAtTop from "../assets/svg/girlsAttop.svg";
import menWithCard from "../assets/svg/menWithCard.svg";
import { Col, Container, Row } from "react-bootstrap";
import { SubContainer } from "./accountant/Accountant";
import invoiceBill from "../assets/svg/invoiceBill.svg";
import invoices from "../assets/svg/invoices.svg";

import {
  Button,
  Image,
  LinkText,
  ListItemCol,
  PointerDiv,
  PrimaryText,
  SecondaryText,
  TitleText,
} from "../styledComponent/styleComponent";
import accountant from "../assets/svg/accountant.svg";
import { PricingSolution } from "./Home";
import { useLocation, useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";

export const PlatformManagement = () => {
  const location = useLocation().pathname;

  return (
    <div className="mb-3">
      <Header
        title={
          location === "/creating-invoices"
            ? "Creating invoices"
            : "A single platform for managing everything"
        }
        subtitle={
          location === "/creating-invoices"
            ? "Make business solutions that benefit from these features, such as voice notifications,invoice reminders, and automated synchronization of payments to receipts."
            : "Book-keeping can be done from a computer, laptop, tablet, or smartphone. You can create access credentials for your accountant and team to access your data online."
        }
        image={location === "/creating-invoices" ? invoices : girlsAtTop}
      />
      <Container className="mt-5">
        <SubContainer
          title="Faster payment with better statements"
          subtitle="We can help you generate professional invoices and get reimbursed in a few days. With cash management online and in one centralized location, individuals like you can receive payments as quickly as they could with printed invoices."
          image={menWithCard}
          rightImage={true}
        />

        <Row className="gy-4 mt-5 d-flex justify-content-between align-items-center">
          <Col md={5}>
            <Fade left delay={100}>
              <Image src={girlsAtTop} alt="" />
            </Fade>
          </Col>
          <Col md={6} className="">
            <TitleText>Streamline your invoices</TitleText>
            <ListItemCol>
              <ListItem
                title="Automate the invoicing process"
                subtitle="Provide discount information, payment terms, etc. Convert your estimate to an invoice in only a few seconds. Make cash flow easier by doing so."
              />
              <ListItem
                title="Real-time management of payments"
                subtitle="You can check whether an invoice is outstanding, has been viewed, or has been paid. Use a computer, smartphone or tablet to get the outcome of any statement. Your customers will be notified via automatic notifications when their invoices are pending or delayed in PivotalERP."
              />
              {/* <PointerDiv padding="0px">
                <ListItem
                  title="Streamline your accounting process with automated invoicing"
                  subtitle="Get accurate invoices automatically and save time with automatic invoicing. PivotalERP has an automated accounting solution for existing clients that enables them to receive automated invoices. Your most trusted clients will receive invoices on time, automatically and individually as you choose. "
                />

                <ListItemCol className="ps-4">
                  <ListItem
                    tick={true}
                    title="Keeping track of invoices"
                    subtitle="We manage your invoice collection with PivotalERP's invoice acquisition software. Your individual follow-up emails can all be sent with PivotalERP."
                  />
                  <ListItem
                    tick={true}
                    title="Automatically deliver repeating invoices to your clients "
                    subtitle="Enhance data quality and save time by digitizing your invoicing workflow. For your most valuable customers, set up ongoing invoices so you can keep focused on your core competencies."
                  />
                  <ListItem
                    tick={true}
                    title="Discounts, taxation, and shipment"
                    subtitle="You will automatically generate invoices with tax, discounts, and shipping costs taken into consideration. In PivotalERP, you have access to your income and payable amounts."
                  />
                </ListItemCol>
              </PointerDiv> */}

              <ListItem
                title="With progress invoices you can tackle more complex projects"
                subtitle="Using PivotalERP's progress invoicing feature, you can accept payments according to deadlines project duration and the percentage of the project completed. Accept payments from different devices and submit invoices against project deadlines."
              />
            </ListItemCol>
          </Col>
        </Row>
        <Row className="gy-4 mt-5 d-flex justify-content-between align-items-center">
          <Col md={6} className="order-2 order-md-1">
            <TitleText>
              Invoice your customers in a timely, personalized manner
            </TitleText>
            <ListItemCol>
              <ListItem
                title="Invoices that are accurate and personalized"
                subtitle="Create personalized invoices for your brand using PivotalERP's invoice samples. With just a few clicks, update your company logo, colors, and payment details. You can see an example invoice to see how your clients will see their invoices."
              />
              <ListItem
                title="Setup and use different currencies with ease"
                subtitle="Most types of accounts, for instance, clients, vendors, and more can be assigned specific currency types and send invoices in different currencies."
              />
            </ListItemCol>
          </Col>
          <Col md={5} className="order-1 order-md-2">
            <Fade right delay={100}>
              <Image src={girlsAtTop} alt="" />
            </Fade>
          </Col>
        </Row>

        <Row className="gy-4 mt-5 d-flex justify-content-between align-items-center">
          <Col md={5}>
            <Fade left delay={100}>
              <Image src={accountant} alt="" maxHeight="300px" />
            </Fade>
          </Col>
          <Col md={6}>
            <TitleText>Automated invoicing saves you time</TitleText>
            <ListItemCol>
              <ListItem
                title="Invoices can be automatically generated"
                subtitle="The PivotalERP automatic invoice feature makes sending ongoing invoices as simple as possible. It lets you send invoices in bulk to reduce your time and work even more efficiently."
              />
              <ListItem
                title="Invoice processing is quick and easy"
                subtitle="The invoice in your book will be synced with the payment you made in PivotalERP to simplify the process and ensure accuracy."
              />
              <ListItem
                title="Here's how it works"
                subtitle="You will automatically be able to calculate VAT, discounts, and shipment charges when creating invoices, and you will have the option to keep track of your earnings & obligations."
              />
            </ListItemCol>
          </Col>
        </Row>
        <Row className="gy-4 mt-5 d-flex justify-content-between align-items-center">
          <Col md={6} className="order-2 order-md-1">
            <TitleText>
              Get invoices sent instantly with all the features you need
            </TitleText>
            <ListItemCol>
              <ListItem
                title="Invoices are accessible at any time, from any location"
                subtitle="Invoices can be created and sent in minutes. You can manage your business with PivotalERP no matter where you are, on any device you prefer."
              />
            </ListItemCol>
          </Col>
          <Col md={5} className="d-flex justify-content-end order-1 order-md-2">
            <Fade right delay={100}>
              <Image src={invoiceBill} alt="" maxHeight="250px" />
            </Fade>
          </Col>
        </Row>
      </Container>
      {/* <PricingSolution title="PivotalERP has a solution for you" /> */}
    </div>
  );
};

export const ListItem = ({ title, subtitle, demoButton, lists, tick }) => {
  const navigateTo = useNavigate();
  return (
    <PointerDiv>
      <Fade left delay={100}>
        <> {tick && <span className="tickIcon">✔</span>}</>
      </Fade>
      <Fade up delay={100}>
        <PrimaryText className="m-0">{title}</PrimaryText>
        <SecondaryText>{subtitle}</SecondaryText>
        <>
          {demoButton && (
            <Button
              background="#009CEA"
              padding=" 5px 22px"
              className="mt-3"
              onClick={() => navigateTo("/request-a-demo")}
            >
              <LinkText color="#fff">Request a demo</LinkText>
            </Button>
          )}
          {lists && lists}
        </>
      </Fade>
    </PointerDiv>
  );
};
