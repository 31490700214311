export const ArrowIcon = () => (
  <svg
    width="63"
    height="67"
    viewBox="0 0 63 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.551025 27.1317L62.6631 66.2893L46.8457 0.319336L0.551025 27.1317Z"
      fill="#0077AD"
    />
  </svg>
);

export const ArrowBottom = () => (
  <svg
    width="50"
    height="81"
    viewBox="0 0 50 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      position: "absolute",
      right: "0px",
      bottom: "-50px",
      zIndex: "-2",
      //   boxShadow: "rgba(96, 96, 96, 0.05) 0px 0px 0px 8px",
    }}
  >
    <path
      d="M49.4413 31.016L0.253174 80.2041V0.345703L49.4413 31.016Z"
      fill="#DDDEDF"
    />
  </svg>
);

export const LineArrow = ({ margin }) => (
  <svg
    width="38"
    height="23"
    viewBox="0 0 38 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      position: "absolute",
      left: margin ? `${margin}` : "0px",
      top: "4px",
    }}
  >
    <path
      d="M37.4936 12.6407C38.0793 12.055 38.0793 11.1052 37.4936 10.5194L27.9476 0.973476C27.3618 0.38769 26.4121 0.38769 25.8263 0.973476C25.2405 1.55926 25.2405 2.50901 25.8263 3.0948L34.3116 11.5801L25.8263 20.0654C25.2405 20.6511 25.2405 21.6009 25.8263 22.1867C26.4121 22.7725 27.3618 22.7725 27.9476 22.1867L37.4936 12.6407ZM0.278076 13.0801H36.4329V10.0801H0.278076V13.0801Z"
      fill="#0D97D6"
    />
  </svg>
);

export const WaveSvg = ({ waveHeight }) => (
  <svg
    className={`pricing-deco-img ${waveHeight}`}
    enableBackground="new 0 0 300 100"
    height="90px"
    id="Layer_1"
    preserveAspectRatio="none"
    version="1.1"
    viewBox="0 0 300 100"
    width="100%"
    x="0px"
    xmlSpace="preserve"
    y="0px"
    style={{ margin: "0px 0px -2px 0px" }}
  >
    <g clipPath="url(#clip0_4_7)">
      <path
        className="deco-layer deco-layer--1"
        opacity="0.6"
        d="M30.913 43.9439C30.913 43.9439 73.824 9.47992 118.423 29.7529C195.733 64.8929 231.727 27.8009 265.061 25.0239C313.715 20.9679 331 54 331 54L335.001 95.6379H30.913V43.9439Z"
        fill="white"
      />
      <path
        className="deco-layer deco-layer--2"
        opacity="0.6"
        d="M-35.667 44.6281C-35.667 44.6281 7.243 10.1651 51.843 30.4371C129.153 65.5781 165.147 28.4851 198.482 25.7081C247.135 21.6531 268.421 41.9261 268.421 41.9261V96.3221H-35.667V44.6281Z"
        fill="white"
      />
      <path
        className="deco-layer deco-layer--3"
        opacity="0.7"
        d="M43.415 98.342C43.415 98.342 91.698 29.415 152.548 29.415C218.434 29.415 250.531 97.329 250.531 97.329V101.045H42.401L43.415 98.342Z"
        fill="white"
      />
      <path
        className="deco-layer deco-layer--4"
        d="M-34.667 62.9979C-34.667 62.9979 21.333 17.3309 85.649 35.1589C167.484 57.8419 197 41.3319 232.286 30.4279C285.356 14.0289 337.666 75 337.666 75V103.998L-34.334 101.044L-34.667 62.9979Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4_7">
        <rect width="300" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const PlusIcon = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 6.80371V0.803711H8V6.80371H14V8.80371H8V14.8037H6V8.80371H0V6.80371H6Z"
      fill="#222222"
    />
  </svg>
);

export const DashedIcon = () => (
  <svg
    width="14"
    height="2"
    viewBox="0 0 14 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 0.0717773H8H14V1.76236H8H6H0V0.0717773H6Z" fill="#222222" />
  </svg>
);

export const HalfWave = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 737 482"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M241.281 179.258C276.149 124.794 255.809 37.4313 241.281 0.557861H736.221V481.08H18.1414C18.1414 481.08 3.13298 439.518 0.988203 411.684C-9.04213 281.514 197.695 247.338 241.281 179.258Z"
      fill="#E2DFDF"
    />
  </svg>
);

export const LockIcon = () => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3055 7.87462C21.3055 9.40874 22.7182 10.5509 23.8637 11.5714C24.4173 12.0646 24.7555 12.7171 24.7555 13.4302V23.1524C24.7555 24.6802 23.203 25.9302 21.3055 25.9302H4.05547C2.15797 25.9302 0.605469 24.6802 0.605469 23.1524V13.4302C0.605469 12.7171 0.943669 12.0646 1.4973 11.5714C2.6428 10.5509 4.05547 9.40874 4.05547 7.87462C4.05547 4.04129 7.91948 0.930176 12.6805 0.930176C17.4415 0.930176 21.3055 4.04129 21.3055 7.87462ZM9.23048 7.87462C9.23048 9.40874 10.4741 10.6524 12.0083 10.6524H13.3527C14.8868 10.6524 16.1305 9.40874 16.1305 7.87462C16.1305 6.34684 14.578 5.09684 12.6805 5.09684C10.783 5.09684 9.23048 6.34684 9.23048 7.87462ZM12.6805 22.1107C13.4426 22.1107 14.0605 21.4929 14.0605 20.7307V20.3432C14.0605 19.5958 14.5658 18.9663 15.1432 18.4919C15.7511 17.9926 16.1305 17.3105 16.1305 16.5552C16.1305 15.0274 14.578 13.7774 12.6805 13.7774C10.783 13.7774 9.23048 15.0274 9.23048 16.5552C9.23048 17.3105 9.60988 17.9926 10.2177 18.4919C10.7952 18.9663 11.3005 19.5958 11.3005 20.3432V20.7307C11.3005 21.4929 11.9183 22.1107 12.6805 22.1107Z"
      fill="#0D97D6"
    />
  </svg>
);

export const SingleUserIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4754 0.430176C10.8288 0.430176 9.54239 1.00855 8.61618 2.16529C7.68997 3.32204 7.22687 4.80071 7.22687 6.60131C7.21544 8.83841 7.97012 10.6117 9.49093 11.9212C9.85591 12.2491 9.5853 12.8144 9.36516 13.2528L9.30226 13.3781C9.17648 13.64 8.99066 13.8446 8.74482 13.9919C8.49898 14.1393 7.98156 14.3493 7.19257 14.6221C7.15826 14.6331 6.43502 14.8568 5.02284 15.2933C3.61067 15.7298 2.85884 15.9699 2.76736 16.0135C2.06195 16.294 1.5354 16.5833 1.1877 16.8815C0.960582 17.0763 0.897841 17.1241 0.802136 17.4076C-0.0490034 19.9285 2.55736 22.4302 5.21812 22.4302H19.7326C22.3934 22.4302 24.9997 19.9285 24.1486 17.4076C24.0529 17.1241 23.9902 17.0763 23.763 16.8815C23.4153 16.5833 22.8888 16.294 22.1834 16.0135C22.0919 15.9699 21.3401 15.7298 19.9279 15.2933C18.5157 14.8568 17.7925 14.6331 17.7582 14.6221C16.9692 14.3493 16.4518 14.1393 16.2059 13.9919C15.9601 13.8446 15.7743 13.64 15.6485 13.3781L15.5856 13.2528C15.3654 12.8144 15.0948 12.2491 15.4598 11.9212C16.9806 10.6117 17.7353 8.83841 17.7239 6.60131C17.7239 4.80071 17.2608 3.32204 16.3346 2.16529C15.4084 1.00855 14.122 0.430176 12.4754 0.430176Z"
      fill="#0D97D6"
    />
  </svg>
);

export const MailIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 29 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "10px" }}
  >
    <path
      d="M3.81379 1.2312H24.9803C26.4355 1.2312 27.6261 2.40942 27.6261 3.84946V19.559C27.6261 20.999 26.4355 22.1772 24.9803 22.1772H3.81379C2.35859 22.1772 1.16797 20.999 1.16797 19.559V3.84946C1.16797 2.40942 2.35859 1.2312 3.81379 1.2312Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.6261 3.84937L14.3971 13.0133L1.16797 3.84937"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PhoneIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "10px" }}
  >
    <path
      d="M20.8813 11.4023H23.3182C23.3182 5.15152 18.5991 0.437256 12.3398 0.437256V2.8742C17.2893 2.8742 20.8813 6.46016 20.8813 11.4023Z"
      fill="white"
    />
    <path
      d="M12.353 7.74815C14.9154 7.74815 16.0084 8.84112 16.0084 11.4036H18.4453C18.4453 7.474 16.2826 5.31121 12.353 5.31121V7.74815ZM16.5226 14.3803C16.2885 14.1675 15.9808 14.054 15.6646 14.0638C15.3483 14.0735 15.0483 14.2058 14.8277 14.4327L11.9119 17.4313C11.2101 17.2973 9.79909 16.8574 8.34668 15.4087C6.89426 13.955 6.45439 12.5404 6.32402 11.8434L9.32023 8.92641C9.54737 8.70603 9.67985 8.40591 9.68963 8.08958C9.6994 7.77324 9.58572 7.46552 9.37263 7.23152L4.87038 2.28087C4.6572 2.04614 4.36092 1.90376 4.04445 1.88397C3.72798 1.86418 3.41626 1.96853 3.17549 2.17487L0.531416 4.44244C0.320756 4.65387 0.195021 4.93525 0.17806 5.23323C0.159783 5.53785 -0.188699 12.7536 5.40651 18.3513C10.2877 23.2313 16.402 23.5883 18.0859 23.5883C18.332 23.5883 18.4831 23.581 18.5233 23.5785C18.8213 23.5618 19.1025 23.4355 19.3129 23.2239L21.5793 20.5786C21.7857 20.338 21.8903 20.0264 21.8707 19.7099C21.8512 19.3935 21.709 19.0971 21.4745 18.8838L16.5226 14.3803Z"
      fill="white"
    />
  </svg>
);

export const Location = () => (
  <svg
    width="22"
    height="27"
    viewBox="0 0 22 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "10px" }}
  >
    <path
      d="M10.641 0.581787C7.86438 0.584944 5.20243 1.64932 3.23907 3.54144C1.27571 5.43356 0.171256 7.99891 0.16798 10.6748C0.164654 12.8615 0.905833 14.9889 2.27782 16.7306C2.27782 16.7306 2.56344 17.093 2.6101 17.1453L10.641 26.273L18.6757 17.1407C18.7176 17.0921 19.0042 16.7306 19.0042 16.7306L19.0051 16.7278C20.3764 14.9869 21.1173 12.8605 21.114 10.6748C21.1107 7.99891 20.0063 5.43356 18.0429 3.54144C16.0796 1.64932 13.4176 0.584944 10.641 0.581787ZM14.6493 11.3189L10.6438 15.2625L6.63268 11.3189C6.15106 10.8397 5.88176 10.1987 5.88176 9.53151C5.88176 8.86435 6.15106 8.22336 6.63268 7.74413C6.86886 7.51018 7.1513 7.32423 7.46332 7.19725C7.77533 7.07027 8.11058 7.00485 8.44928 7.00485C8.78797 7.00485 9.12322 7.07027 9.43524 7.19725C9.74725 7.32423 10.0297 7.51018 10.2659 7.74413L10.6438 8.11574L11.018 7.74413C11.2542 7.51018 11.5366 7.32423 11.8487 7.19725C12.1607 7.07027 12.4959 7.00485 12.8346 7.00485C13.1733 7.00485 13.5086 7.07027 13.8206 7.19725C14.1326 7.32423 14.415 7.51018 14.6512 7.74413C15.1328 8.22336 15.4021 8.86435 15.4021 9.53151C15.4021 10.1987 15.1328 10.8397 14.6512 11.3189H14.6493Z"
      fill="white"
    />
  </svg>
);

export const TickIcon = () => (
  <svg
    height="20px"
    version="1.1"
    viewBox="0 0 64 64"
    width="20px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title />
    <desc />
    <g fill="none" fill-rule="evenodd" id="Tick" stroke="none" stroke-width="1">
      <g fill="#000000" id="Group" transform="translate(9.000000, 13.000000)">
        <path
          d="M12.5900974,34.4099026 C13.5664082,35.3862133 15.1493206,35.3862133 16.1256313,34.4099026 L44.4099026,6.12563133 C45.3862133,5.1493206 45.3862133,3.56640815 44.4099026,2.59009742 C43.4335918,1.61378669 41.8506794,1.61378669 40.8743687,2.59009742 L12.5900974,30.8743687 C11.6137867,31.8506794 11.6137867,33.4335918 12.5900974,34.4099026 Z"
          id="Rectangle"
        />
        <path
          d="M1.42893219,19.9289322 C2.40524292,18.9526215 3.98815536,18.9526215 4.96446609,19.9289322 L15.5710678,30.5355339 C16.5473785,31.5118446 16.5473785,33.0947571 15.5710678,34.0710678 C14.5947571,35.0473785 13.0118446,35.0473785 12.0355339,34.0710678 L1.42893219,23.4644661 C0.452621459,22.4881554 0.452621459,20.9052429 1.42893219,19.9289322 Z"
          id="Rectangle"
        />
      </g>
    </g>
  </svg>
);

export const Facebook = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.375 11.1754C0.375 16.3957 4.16638 20.7366 9.125 21.6168V14.0332H6.5V11.1168H9.125V8.7832C9.125 6.1582 10.8164 4.70045 13.2086 4.70045C13.9664 4.70045 14.7836 4.81682 15.5414 4.9332V7.61682H14.2C12.9164 7.61682 12.625 8.2582 12.625 9.07545V11.1168H15.425L14.9586 14.0332H12.625V21.6168C17.5836 20.7366 21.375 16.3966 21.375 11.1754C21.375 5.36807 16.65 0.616821 10.875 0.616821C5.1 0.616821 0.375 5.36807 0.375 11.1754Z"
      fill="black"
    />
  </svg>
);

export const Instagram = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8722 7.61533C8.94416 7.61533 7.37075 9.18877 7.37075 11.1168C7.37075 13.0449 8.94416 14.6183 10.8722 14.6183C12.8002 14.6183 14.3736 13.0449 14.3736 11.1168C14.3736 9.18877 12.8002 7.61533 10.8722 7.61533ZM21.3738 11.1168C21.3738 9.66684 21.387 8.23 21.3055 6.78264C21.2241 5.10151 20.8406 3.6095 19.6113 2.38017C18.3794 1.14821 16.89 0.767329 15.2089 0.685899C13.7589 0.604469 12.3221 0.617603 10.8748 0.617603C9.42485 0.617603 7.98803 0.604469 6.54071 0.685899C4.8596 0.767329 3.36762 1.15084 2.13831 2.38017C0.90638 3.61213 0.525505 5.10151 0.444076 6.78264C0.362648 8.23262 0.375781 9.66947 0.375781 11.1168C0.375781 12.5642 0.362648 14.0036 0.444076 15.451C0.525505 17.1321 0.909007 18.6241 2.13831 19.8535C3.37025 21.0854 4.8596 21.4663 6.54071 21.5477C7.99066 21.6292 9.42748 21.616 10.8748 21.616C12.3248 21.616 13.7616 21.6292 15.2089 21.5477C16.89 21.4663 18.382 21.0828 19.6113 19.8535C20.8432 18.6215 21.2241 17.1321 21.3055 15.451C21.3896 14.0036 21.3738 12.5668 21.3738 11.1168ZM10.8722 16.5043C7.89084 16.5043 5.48476 14.0982 5.48476 11.1168C5.48476 8.13543 7.89084 5.72931 10.8722 5.72931C13.8535 5.72931 16.2596 8.13543 16.2596 11.1168C16.2596 14.0982 13.8535 16.5043 10.8722 16.5043ZM16.4802 6.76688C15.7842 6.76688 15.222 6.20475 15.222 5.50866C15.222 4.81256 15.7842 4.25043 16.4802 4.25043C17.1763 4.25043 17.7384 4.81256 17.7384 5.50866C17.7386 5.67395 17.7062 5.83766 17.6431 5.9904C17.5799 6.14315 17.4873 6.28194 17.3704 6.39882C17.2535 6.5157 17.1147 6.60837 16.962 6.67153C16.8092 6.73469 16.6455 6.76709 16.4802 6.76688Z"
      fill="black"
    />
  </svg>
);

export const Twitter = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.875 0.616821C5.07656 0.616821 0.375 5.31838 0.375 11.1168C0.375 16.9153 5.07656 21.6168 10.875 21.6168C16.6734 21.6168 21.375 16.9153 21.375 11.1168C21.375 5.31838 16.6734 0.616821 10.875 0.616821ZM15.9211 8.53167C15.9281 8.64182 15.9281 8.75667 15.9281 8.86917C15.9281 12.3098 13.3078 16.2731 8.51953 16.2731C7.04297 16.2731 5.67422 15.8442 4.52109 15.1059C4.73203 15.1293 4.93359 15.1387 5.14922 15.1387C6.36797 15.1387 7.48828 14.7262 8.38125 14.0278C7.2375 14.0043 6.27656 13.2543 5.94844 12.2231C6.34922 12.2817 6.71016 12.2817 7.12266 12.1762C6.53373 12.0565 6.00439 11.7367 5.62457 11.271C5.24474 10.8053 5.03786 10.2225 5.03906 9.62151V9.5887C5.38359 9.78323 5.78906 9.90276 6.21328 9.91916C5.85666 9.6815 5.5642 9.3595 5.36182 8.98174C5.15945 8.60397 5.05341 8.1821 5.05313 7.75354C5.05313 7.26838 5.17969 6.82542 5.40703 6.44104C6.06071 7.24573 6.8764 7.90387 7.80109 8.37268C8.72578 8.84148 9.73877 9.11046 10.7742 9.16213C10.4062 7.3926 11.7281 5.96057 13.3172 5.96057C14.0672 5.96057 14.7422 6.27463 15.218 6.78088C15.8062 6.67073 16.3687 6.45041 16.8703 6.1551C16.6758 6.75745 16.268 7.26604 15.7266 7.58713C16.2516 7.53088 16.7578 7.38557 17.2266 7.18167C16.8727 7.70198 16.4297 8.1637 15.9211 8.53167Z"
      fill="black"
    />
  </svg>
);

export const Linkedin = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.375 2.37128C0.375 1.90597 0.559844 1.45972 0.888868 1.13069C1.21789 0.801667 1.66414 0.616824 2.12945 0.616824H19.6186C19.8492 0.616447 20.0776 0.661556 20.2908 0.749569C20.5039 0.837582 20.6976 0.966769 20.8607 1.12974C21.0239 1.2927 21.1533 1.48625 21.2415 1.69929C21.3298 1.91233 21.3751 2.14068 21.375 2.37128V19.8605C21.3752 20.0911 21.33 20.3195 21.2419 20.5327C21.1538 20.7458 21.0245 20.9395 20.8615 21.1026C20.6984 21.2657 20.5048 21.3951 20.2917 21.4834C20.0786 21.5716 19.8502 21.6169 19.6196 21.6168H2.12945C1.89898 21.6168 1.67076 21.5714 1.45783 21.4832C1.24491 21.395 1.05146 21.2656 0.88853 21.1026C0.725602 20.9396 0.59639 20.7461 0.508276 20.5331C0.420163 20.3201 0.374875 20.0919 0.375 19.8614V2.37128ZM8.68718 8.62355H11.5308V10.0515C11.9412 9.23064 12.9912 8.49182 14.5691 8.49182C17.594 8.49182 18.3109 10.127 18.3109 13.1271V18.6845H15.2497V13.8105C15.2497 12.1019 14.8392 11.1378 13.7969 11.1378C12.3507 11.1378 11.7494 12.1773 11.7494 13.8105V18.6845H8.68718V8.62355ZM3.43718 18.5537H6.49936V8.49182H3.43718V18.5527V18.5537ZM6.9375 5.2101C6.94327 5.47228 6.89662 5.73298 6.80027 5.9769C6.70393 6.22081 6.55983 6.44302 6.37645 6.63049C6.19306 6.81796 5.97408 6.96691 5.73234 7.0686C5.49061 7.17029 5.231 7.22268 4.96875 7.22268C4.7065 7.22268 4.44689 7.17029 4.20516 7.0686C3.96342 6.96691 3.74444 6.81796 3.56105 6.63049C3.37766 6.44302 3.23357 6.22081 3.13723 5.9769C3.04088 5.73298 2.99423 5.47228 3 5.2101C3.01133 4.69546 3.22373 4.20571 3.59171 3.84575C3.95969 3.48579 4.45399 3.28422 4.96875 3.28422C5.48351 3.28422 5.97781 3.48579 6.34579 3.84575C6.71377 4.20571 6.92617 4.69546 6.9375 5.2101Z"
      fill="black"
    />
  </svg>
);

export const Calendar = () => (
  <svg
    width="28"
    height="27"
    viewBox="0 0 28 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "12px" }}
  >
    <path
      d="M24.3303 5.16488V4.89409C24.3303 2.45549 22.3538 0.479004 19.9152 0.479004C17.4766 0.479004 15.5002 2.45549 15.5002 4.89409H12.5568C12.5568 2.45549 10.5803 0.479004 8.14168 0.479004C5.70308 0.479004 3.72659 2.45549 3.72659 4.89409V5.16488C2.01796 5.77563 0.783203 7.39303 0.783203 9.30917V22.5544C0.783203 24.9886 2.7641 26.9695 5.19829 26.9695H22.8586C25.2928 26.9695 27.2737 24.9886 27.2737 22.5544V9.30917C27.2737 7.39303 26.039 5.77563 24.3303 5.16488ZM18.4435 4.89409C18.4435 4.08171 19.1014 3.42239 19.9152 3.42239C20.7291 3.42239 21.3869 4.08171 21.3869 4.89409V7.83748C21.3869 8.64985 20.7291 9.30917 19.9152 9.30917C19.1014 9.30917 18.4435 8.64985 18.4435 7.83748V4.89409ZM6.66998 4.89409C6.66998 4.08171 7.32783 3.42239 8.14168 3.42239C8.95553 3.42239 9.61337 4.08171 9.61337 4.89409V7.83748C9.61337 8.64985 8.95553 9.30917 8.14168 9.30917C7.32783 9.30917 6.66998 8.64985 6.66998 7.83748V4.89409ZM24.3303 22.5544C24.3303 23.3653 23.671 24.0261 22.8586 24.0261H5.19829C4.38591 24.0261 3.72659 23.3653 3.72659 22.5544V13.7243H24.3303V22.5544Z"
      fill="#0D97D6"
    />
  </svg>
);

export const UserIcon = () => (
  <svg
    width="27"
    height="32"
    viewBox="0 0 27 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "12px" }}
  >
    <path
      d="M21.1173 7.6302C21.1173 9.58179 20.3421 11.4534 18.9621 12.8334C17.5821 14.2134 15.7105 14.9887 13.7589 14.9887C11.8073 14.9887 9.93562 14.2134 8.55564 12.8334C7.17566 11.4534 6.40039 9.58179 6.40039 7.6302C6.40039 5.67861 7.17566 3.80696 8.55564 2.42698C9.93562 1.04699 11.8073 0.271729 13.7589 0.271729C15.7105 0.271729 17.5821 1.04699 18.9621 2.42698C20.3421 3.80696 21.1173 5.67861 21.1173 7.6302V7.6302Z"
      fill="#0D97D6"
    />
    <path
      d="M13.7587 16.4604C11.8794 16.4604 10.0879 16.8563 8.46976 17.5642C4.22304 19.4215 1.14352 23.4466 0.605469 28.234C0.605469 29.8602 1.83124 31.1774 3.45688 31.1774H24.0606C25.6868 31.1774 26.9127 29.8602 26.9127 28.234C26.3741 23.4466 23.2939 19.4215 19.048 17.5642C17.4291 16.8563 15.6381 16.4604 13.7587 16.4604V16.4604Z"
      fill="#0D97D6"
    />
  </svg>
);
