import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Modal, Navbar } from "react-bootstrap";
import {
  Button,
  CustomLink,
  ErrorText,
  Line,
  LinkText,
  NavContainer,
  PrimaryText,
  SecondaryText,
} from "../../styledComponent/styleComponent";
import logo from "../../assets/pivotalerplogo.png";
import logoColor from "../../assets/pivotalerplogoColor.png";
import { useForm } from "react-hook-form";
import { LockIcon, SingleUserIcon } from "../../assets/svg/IconSvg";

const NavLink = ({ navTo, path, setExpanded }) => {
  return (
    <>
      <CustomLink
        to={navTo === "/" ? "/" : "/" + navTo.toLowerCase()}
        currentnav={path === `/${navTo.toLowerCase()}` ? `#74B750` : `#0D97D6`}
        onClick={() => {
          setExpanded(false);
          if (path !== "/" + navTo) {
            window.scrollTo(0, 0);
          }
        }}
      >
        <LinkText>
          {navTo === "/" ? "home" : navTo.replace(/[^a-zA-Z]/g, " ")}
        </LinkText>
      </CustomLink>
    </>
  );
};

export function NavBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const isBlogDetail = location.pathname === "/blogdetail";
  const [stickyNav, setSticky] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.onscroll = function () {
      let navbar = document.getElementById("stickyNavBar");
      let sticky = navbar?.offsetTop;
      addClass(sticky, navbar);
    };
  });

  function addClass(sticky, navbar) {
    if (window.scrollY > 120) {
      navbar.classList.add("stickyNav");
      document.body.style.paddingTop = 75 + "px";
      navbar.style.height = "75px";
      navbar.style.background = "#fff";
      setSticky(true);
    } else {
      navbar.classList.remove("stickyNav");
      document.body.style.paddingTop = "0";
      navbar.style.height = "85px";
      navbar.style.background = "transparent";
      setSticky(false);
    }
  }

  return (
    <>
      <Navbar
        expanded={expanded}
        expand="lg"
        id="stickyNavBar"
        className="w-100 p-0  "
        style={{
          height: "85px",
          zIndex: "999",
          boxShadow:
            isBlogDetail &&
            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
        }}
      >
        <Container className="">
          <Navbar.Brand
            className="p-0 c-pointer"
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
          >
            {stickyNav || isBlogDetail ? (
              <img src={logoColor} alt="" height="60px" />
            ) : (
              <img src={logo} alt="" height="60px" />
            )}
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          >
            <Line
              transform={
                expanded === "expanded"
                  ? "translateY(13px) rotate(45deg)"
                  : "translateY(0px) rotate(0deg)"
              }
            />
            <Line opacity={expanded === "expanded" ? "0" : "0.25"} />
            <Line
              transform={
                expanded === "expanded"
                  ? "translateY(-13px) rotate(-45deg)"
                  : "translateY(0px) rotate(0deg)"
              }
            />
          </Navbar.Toggle>
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end position-relative"
          >
            <NavContainer activeKey={location.pathname}>
              <NavLink
                navTo="/"
                path={location.pathname}
                setExpanded={setExpanded}
              />
              <NavLink
                navTo="features"
                path={location.pathname}
                setExpanded={setExpanded}
              />
              <NavLink
                navTo="plans"
                path={location.pathname}
                setExpanded={setExpanded}
              />
              <NavLink
                navTo="Accountant"
                path={location.pathname}
                setExpanded={setExpanded}
              />
              {/* <NavLink
                navTo="Resources"
                path={location.pathname}
                setExpanded={setExpanded}
              /> */}
              <NavLink
                navTo="Support"
                path={location.pathname}
                setExpanded={setExpanded}
              />
              <NavLink
                navTo="Contact"
                path={location.pathname}
                setExpanded={setExpanded}
              />

              <Button onClick={() => setModalShow(true)}>
                <LinkText>Log in</LinkText>
              </Button>
            </NavContainer>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

function MyVerticallyCenteredModal(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Container className="w-75 p-4">
        <div>
          <div
            className="d-flex justify-content-center align-items-center mb-3"
            style={{ height: "100px" }}
          >
            <img src={logoColor} alt="" height="80px" />
          </div>

          <PrimaryText color="#0D97D6">Login</PrimaryText>
          <SecondaryText>
            Welcome back, please login to your account.
          </SecondaryText>

          <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
            <div className="input-group ">
              <span
                className="input-group-text bg-transparent"
                id="basic-addon1"
              >
                <SingleUserIcon />
              </span>
              <input
                type="text"
                className="form-control input-login"
                placeholder="Email"
                aria-describedby="basic-addon1"
                {...register("email", {
                  required: "Please enter email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Please enter a valid email",
                  },
                })}
              />
            </div>
            {errors?.email && errors?.email?.message && (
              <ErrorText>{errors?.email?.message}</ErrorText>
            )}

            <div className="input-group  mt-4">
              <span
                className="input-group-text bg-transparent"
                id="basic-addon1"
              >
                <LockIcon />
              </span>
              <input
                type="password"
                className="form-control input-login "
                placeholder="Password"
                aria-describedby="basic-addon1"
                {...register("password", {
                  required: "Please enter password",
                })}
              />
            </div>
            {errors?.password && errors?.password?.message && (
              <ErrorText>{errors?.password?.message}</ErrorText>
            )}
            <div className="d-flex  align-items-center justify-content-between p-1 mt-4">
              <div className="d-flex  align-items-center">
                <input
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "20px",
                  }}
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                  {...register("remeber", {})}
                />
                <p className="p-0 m-0">Remember Me</p>
              </div>

              <Button type="submit" padding="6px 16px" background="#0D97D6">
                <p className="m-0 text-white">Login</p>
              </Button>
            </div>
            <LinkText color="#36601F" className="my-4 text-center">
              Forgot Password ?
            </LinkText>
          </form>
        </div>
      </Container>
    </Modal>
  );
}
