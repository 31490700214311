import { Col, Container, Row } from "react-bootstrap";
import { SecondaryText, TitleText } from "../../styledComponent/styleComponent";
import { BlogCard } from "./BlogCard";

export const Blogs = () => {
  return (
    <>
      <Container className="mt-5">
        <SecondaryText>PivotalERP Blog</SecondaryText>
        <TitleText>Latest from our Blog</TitleText>
        <Row className="g-2">
          <Col sm={6} md={4} lg={4} xxl={3}>
            <BlogCard />
          </Col>
        </Row>
      </Container>
    </>
  );
};
