import { Header } from "./HeaderSection";
import analysisWithLens from "../assets/svg/analysisWithLens.svg";
import girlsAtTop from "../assets/svg/girlsAttop.svg";
import { Col, Container, Row } from "react-bootstrap";
import detailReport from "../assets/svg/detailReport.svg";
import track from "../assets/svg/track.svg";

import {
  Image,
  ListItemCol,
  TitleText,
  UlList,
} from "../styledComponent/styleComponent";
import { ListItem } from "./PlatformManagement";

export const ChoseUs = () => (
  <>
    <Header
      title="Why to choose us?"
      subtitle="Making informed decisions regarding finance is possible with PivotalERP."
      image={analysisWithLens}
    />
    <Container className="mt-5 mb-5">
      <Row className="m-0 mt-5 d-flex justify-content-between align-items-center">
        <Col md={6} className="pe-0 mt-md-0 mt-4">
          <TitleText>Managing your finances</TitleText>
          <ListItemCol>
            <ListItem
              title="Manage your finances with one dashboard"
              subtitle="Making informed decisions regarding finance is possible with PivotalERP."
              lists={
                <ul className="p-0 ps-3">
                  <UlList className="ps-1 ">
                    Transactions can be tracked and searched by value,
                    recipient, and sort order.
                  </UlList>
                  <UlList className="ps-1">
                    You can protect your finances by spotting and blocking any
                    fraudulent transactions.
                  </UlList>
                  <UlList className="ps-1">
                    It is possible to organize your transactions with your
                    family or friends in an efficient way.
                  </UlList>
                </ul>
              }
            />
            <ListItem
              title="Set up and manage your own payment types"
              lists={
                <ul className="p-0 ps-3">
                  <UlList className="ps-1 ">
                    Organizes transactions and invoices for you seamlessly.
                  </UlList>
                  <UlList className="ps-1">
                    With PivotalERP, you can customize the category for your
                    spending, and the program will keep track of it for future
                    transactions.
                  </UlList>
                  <UlList className="ps-1">
                    Your monthly expenses, including home lease, groceries, and
                    transportation, can be tracked.
                  </UlList>
                  <UlList className="ps-1">
                    Identify savings chances by examining trends in spending
                    sorting by weekly, monthly, or yearly.
                  </UlList>
                </ul>
              }
            />
          </ListItemCol>
        </Col>
        <Col md={5} className="ps-0">
          <Image src={girlsAtTop} alt="" />
        </Col>
      </Row>

      <Row className="m-0 mt-5 d-flex justify-content-between align-items-center">
        <Col md={5} className="ps-0">
          <Image src={detailReport} alt="" />
        </Col>
        <Col md={6} className="pe-0 mt-md-0 mt-4">
          <TitleText>Plan your budget</TitleText>
          <ListItemCol>
            <ListItem
              title="Get a better grasp of your finances by understanding your income and expenditures"
              lists={
                <ul className="p-0 ps-3">
                  <UlList className="ps-1 ">
                    Connect your financial accounts so that spending data is
                    automatically downloaded.
                  </UlList>
                  <UlList className="ps-1">
                    Find out where you can save money.
                  </UlList>
                  <UlList className="ps-1">
                    Establish more specific, manageable spending goals by
                    identifying everyday expenses that you might not have
                    considered.
                  </UlList>
                </ul>
              }
            />
            <ListItem
              title="Create a budget that works for you"
              lists={
                <ul className="p-0 ps-3">
                  <UlList className="ps-1 ">
                    With PivotalERP, you are able to create a budget based on
                    your commonly repeated purchases.
                  </UlList>
                  <UlList className="ps-1">
                    Sections related to budgets can be included or omitted.
                  </UlList>
                  <UlList className="ps-1">
                    You can choose to automatically update transactions or make
                    manual updates.
                  </UlList>
                </ul>
              }
            />
          </ListItemCol>
        </Col>
      </Row>

      <Row className="m-0 mt-5 d-flex justify-content-between align-items-center">
        <Col md={6} className="pe-0 mt-md-0 mt-4">
          <TitleText>Track of investments</TitleText>
          <ListItemCol>
            <ListItem
              title="All your investment accounts are tracked on one platform"
              lists={
                <ul className="p-0 ps-3">
                  <UlList className="ps-1 ">
                    You can find out how changing market conditions can impact
                    your investment.
                  </UlList>
                  <UlList className="ps-1">
                    Easily track investment fees on a single dashboard. Discover
                    true market returns.
                  </UlList>
                </ul>
              }
            />
            <ListItem
              title="Get insight into your investments and maximize your returns"
              lists={
                <ul className="p-0 ps-3">
                  <UlList className="ps-1 ">
                    With pivotalERP's accounting software, you can transfer
                    money between accounts efficiently.
                  </UlList>
                  <UlList className="ps-1">
                    With stock watch lists, you can monitor investment
                    opportunities
                  </UlList>
                  <UlList className="ps-1">
                    Analyze ROI with performance management reports and keep an
                    eye on your goal achievement.
                  </UlList>
                </ul>
              }
            />
          </ListItemCol>
        </Col>
        <Col md={5} className="ps-0">
          <Image src={track} alt="" />
        </Col>
      </Row>
    </Container>
  </>
);
