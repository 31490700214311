import { Header } from "./HeaderSection";
import bearish from "../assets/svg/bearish.svg";
import expenseTrack from "../assets/svg/expenseTrack.svg";
import accountant from "../assets/svg/accountant.svg";
import auditing from "../assets/svg/auditing.svg";
import taxes from "../assets/svg/taxes.svg";
import girlsAtTop from "../assets/svg/girlsAttop.svg";
import analysis from "../assets/svg/analysis.svg";
import { Col, Container, Row } from "react-bootstrap";
import { SubContainer } from "./accountant/Accountant";
import {
  Image,
  ListItemCol,
  SecondaryText,
  TitleText,
  UlList,
} from "../styledComponent/styleComponent";
import { ListItem } from "./PlatformManagement";
import { PricingSolution } from "./Home";

export const AmountIncurred = () => (
  <>
    <Header
      title="Amounts incurred"
      subtitle="You can consolidate all your expenses under one roof and prepare for tax season."
      image={bearish}
    />
    <Container className="mt-5">
      <SubContainer
        title="Business expenses can tracked easily"
        subtitle="Keep track of all your business expenses from anywhere, from any device."
        rightImage={true}
        image={expenseTrack}
        lists={
          <ul className="p-0 ps-3">
            <UlList className="ps-2">
              <SecondaryText>Import costs and revenue</SecondaryText>
            </UlList>
            <UlList className="ps-2">
              <SecondaryText>An easy-to-manage organization</SecondaryText>
            </UlList>
            <UlList className="ps-2">
              <SecondaryText>
                Ensure that each payment is considered
              </SecondaryText>
            </UlList>
          </ul>
        }
      />
      <SubContainer
        title="Set up an account"
        subtitle="You can integrate PivotalERP with bank accounts, bank cards, and more."
        rightImage={false}
        image={accountant}
      />
      <SubContainer
        title="Sorting by hand is no longer necessary"
        subtitle="Your expenses will be imported and processed instantly. Create specific rules for us to follow or confirm or modify the automatic category selection."
        rightImage={true}
        image={auditing}
      />
      <SubContainer
        title="Get an instant overview of your expenses"
        subtitle="Easily take a snapshot of your invoices using the PivotalERP so they can be linked to your bank transactions in time for tax season."
        rightImage={false}
        image={bearish}
      />
      <SubContainer
        title="Make sure you're prepared for taxes"
        subtitle="With our expense tracking software, never miss an opportunity to claim a tax deduction again. Let PivotalERP build tax classifications for your business to optimize expenses throughout the year, and customize them as needed"
        rightImage={true}
        image={taxes}
      />
      <SubContainer
        title="Cash flow management"
        subtitle="PivotalERP helps you better monitor your cash flow by tracking expenses year-round. You can determine that receiving, investing, and allocating money makes the most sense with cash flow statements."
        rightImage={false}
        image={girlsAtTop}
      />
      <Row className="p-0 m-0 mt-5 d-flex justify-content-between align-items-center">
        <Col md={6} className="p-0 mt-md-0 mt-4 order-2 order-md-1">
          <TitleText>Expense reports are accessible and standardized</TitleText>
          <ListItemCol>
            <ListItem
              title="Maintain a budgetInvoices are accessible at any time, from any location"
              subtitle="Instantly gather vital financial insights to make wiser financial choices. Using the report feature, you can keep track of your business's revenue and expenses."
            />
            <ListItem
              title="Find out what makes a difference"
              subtitle="PivotalERP's profit and loss reports give you insights at a glance. Your accountant will appreciate being able to view reports from your company."
            />
          </ListItemCol>
        </Col>
        <Col
          md={5}
          className="ps-0 d-flex justify-content-end order-1 order-md-2"
        >
          <Image src={analysis} alt="" maxHeight="250px" />
        </Col>
      </Row>
    </Container>
    {/* <PricingSolution title="PivotalERP provides a comprehensive online business plan" /> */}
    {/* <GradientBox title="Discover PivotalERP plans that suit your needs." /> */}
  </>
);
