// import { useNavigate } from "react-router-dom";
import { Button, EllipsisText } from "../../styledComponent/styleComponent";
import "./blog.css";
import upImage from "../../assets/images/upDir.png";
import { useNavigate } from "react-router-dom";

export const BlogCard = () => {
  const navigate = useNavigate();
  const blog = {
    id: 2,
    title: "Why Need to Replace Legacy System with an Pivotal ERP",
    category: "erp",
    date: "Jan 29, 2019",
    image: upImage,
    author: "admin",
    description: (
      <div>
        <p>
          Companies wishing to keep pace with this evolution need to equip
          themselves with business solutions that would help them align and
          prepare for present and future challenges. As technology solves
          various challenges, the business landscape is presenting more pressing
          demands with every passing day.
        </p>
        <h3>Why You need to Replace Your Legacy System with an Pivotal ERP</h3>
        <p>
          While a considerable amount of costs is involved in implementing a
          next-generation ERP, maintaining an old one is more likely to hurt the
          overall business revenue.With the advancement in communications
          technology, the legacy ERP systems no longer fulfill the cause as they
          are not sufficiently powerful to meet the changing business demands.
          Enterprise resource planning solutions are meant to help businesses
          process massive amounts of data for timely and accurate decisions.
        </p>
        <br />
        <p>
          There are multiple reasons why companies need to replace their legacy
          systems with Pivotal ERP solutions.The major Three of them are as
          follows.
        </p>
        <br />
        <h3>Managing your finances</h3>
        <p>
          Making informed decisions regarding finance is possible with
          PivotalERP. Transactions can be tracked and searched by value,
          recipient, and sort order. You can protect your finances by spotting
          and blocking any fraudulent transactions. It is possible to organize
          your transactions with your family or friends in an efficient way.
        </p>
        <p>
          Organizes transactions and invoices for you seamlessly. With
          PivotalERP, you can customize the category for your spending, and the
          program will keep track of it for future transactions. Your monthly
          expenses, including home lease, groceries, and transportation, can be
          tracked. Identify savings chances by examining trends in spending
          sorting by weekly, monthly, or yearly.
        </p>
        <h3>Plan your budget</h3>
        <p>
          Get a better grasp of your finances by understanding your income and
          expenditures Connect your financial accounts so that spending data is
          automatically downloaded. Find out where you can save money. Establish
          more specific, manageable spending goals by identifying everyday
          expenses that you might not have considered.
        </p>
        <p>
          Create a budget that works for you With PivotalERP, you are able to
          create a budget based on your commonly repeated purchases. Sections
          related to budgets can be included or omitted. You can choose to
          automatically update transactions or make manual updates.
        </p>
        <h3>Track of investments</h3>
        <p>
          All your investment accounts are tracked on one platform You can find
          out how changing market conditions can impact your investment. Easily
          track investment fees on a single dashboard. Discover true market
          returns.
        </p>
        <p>
          Get insight into your investments and maximize your returns With
          pivotalERP's accounting software, you can transfer money between
          accounts efficiently. With stock watch lists, you can monitor
          investment opportunities Analyze ROI with performance management
          reports and keep an eye on your goal achievement.
        </p>
        <br />
        <br />
        <p>
          With the evolving business landscape, the best time to start looking
          and implement a new ERP solution is right now. Pivotal Erp has some of
          the best cloud ERP software solutions equipped with advanced features
          that can help you improve the operational efficiency of your company
          and drive growth. Why not get in touch with us for a free demo or
          consultation to have a better understanding of our products and their
          utility.
        </p>
      </div>
    ),
  };

  return (
    <article className="blog-card mt-4 ">
      <div className="blog-card__background">
        <div className="card__background--wrapper">
          <div
            className="card__background--main"
            style={{
              backgroundImage: `url(${blog.image})`,
            }}
          >
            <div className="card__background--layer"></div>
          </div>
        </div>
      </div>
      <div className="blog-card__head">
        <span className="date__box">
          <span className="date__day">11</span>
          <span className="date__month">JAN</span>
        </span>
      </div>
      <div className="blog-card__info">
        <EllipsisText lineClamp="2" fontSize="18px" fontWeight="500">
          {blog.title}
        </EllipsisText>
        <EllipsisText lineClamp="3">{blog.description}</EllipsisText>

        <Button
          className="my-1"
          background="transparent"
          padding="0px"
          color="#000"
          onClick={() => navigate("/blogdetail")}
          border="none"
        >
          Read More
          <svg
            width="12"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.1113 4.79276C11.3066 4.5975 11.3066 4.28092 11.1113 4.08566L7.92933 0.903675C7.73407 0.708413 7.41749 0.708413 7.22223 0.903675C7.02696 1.09894 7.02696 1.41552 7.22223 1.61078L10.0507 4.43921L7.22223 7.26764C7.02696 7.4629 7.02696 7.77948 7.22223 7.97474C7.41749 8.17001 7.73407 8.17001 7.92933 7.97474L11.1113 4.79276ZM0.656006 4.93921H10.7578V3.93921H0.656006V4.93921Z"
              fill="#0D97D6"
            />
          </svg>
        </Button>
      </div>
    </article>
  );
};
