import upImage from "../../assets/images/upDir.png";
import { Col, Container, Row } from "react-bootstrap";

import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  EllipsisText,
  ErrorText,
  Input,
  PrimaryText,
  SecondaryText,
  TitleText,
} from "../../styledComponent/styleComponent";
import {
  Calendar,
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  UserIcon,
} from "../../assets/svg/IconSvg";
import { useForm } from "react-hook-form";

export const BlogDetail = () => {
  const { blogId } = useParams();
  const navigate = useNavigate();

  const blogdetail = {
    id: 1,
    title: "Why Need to Replace Legacy System with an Pivotal ERP",
    date: { day: "14", month: "Oct", year: "2020" },
    category: "erp",
    image: upImage,
    author: { name: "Dynamic Technosoft", avatar: "", position: "Admin" },
    description: (
      <div>
        <p style={{ fontSize: "16px", lineHeight: "22px" }}>
          Companies wishing to keep pace with this evolution need to equip
          themselves with business solutions that would help them align and
          prepare for present and future challenges. As technology solves
          various challenges, the business landscape is presenting more pressing
          demands with every passing day.
        </p>
        <PrimaryText color="#0d97d6">
          Why You need to Replace Your Legacy System with an Pivotal ERP
        </PrimaryText>
        <p style={{ fontSize: "16px", lineHeight: "22px" }}>
          While a considerable amount of costs is involved in implementing a
          next-generation ERP, maintaining an old one is more likely to hurt the
          overall business revenue.With the advancement in communications
          technology, the legacy ERP systems no longer fulfill the cause as they
          are not sufficiently powerful to meet the changing business demands.
          Enterprise resource planning solutions are meant to help businesses
          process massive amounts of data for timely and accurate decisions.
        </p>

        <p style={{ fontSize: "16px", lineHeight: "22px" }}>
          There are multiple reasons why companies need to replace their legacy
          systems with Pivotal ERP solutions.The major Three of them are as
          follows.
        </p>
        <PrimaryText fontSize="20px" color="#0d97d6">
          Managing your finances
        </PrimaryText>

        <p style={{ fontSize: "16px", lineHeight: "22px" }}>
          Making informed decisions regarding finance is possible with
          PivotalERP. Transactions can be tracked and searched by value,
          recipient, and sort order. You can protect your finances by spotting
          and blocking any fraudulent transactions. It is possible to organize
          your transactions with your family or friends in an efficient way.
        </p>
        <p style={{ fontSize: "16px", lineHeight: "22px" }}>
          Organizes transactions and invoices for you seamlessly. With
          PivotalERP, you can customize the category for your spending, and the
          program will keep track of it for future transactions. Your monthly
          expenses, including home lease, groceries, and transportation, can be
          tracked. Identify savings chances by examining trends in spending
          sorting by weekly, monthly, or yearly.
        </p>
        <PrimaryText fontSize="20px" color="#0d97d6">
          Plan your budget
        </PrimaryText>

        <p style={{ fontSize: "16px", lineHeight: "22px" }}>
          Get a better grasp of your finances by understanding your income and
          expenditures Connect your financial accounts so that spending data is
          automatically downloaded. Find out where you can save money. Establish
          more specific, manageable spending goals by identifying everyday
          expenses that you might not have considered.
        </p>
        <p style={{ fontSize: "16px", lineHeight: "22px" }}>
          Create a budget that works for you With PivotalERP, you are able to
          create a budget based on your commonly repeated purchases. Sections
          related to budgets can be included or omitted. You can choose to
          automatically update transactions or make manual updates.
        </p>
        <PrimaryText fontSize="20px" color="#0d97d6">
          Track of investments
        </PrimaryText>
        <p style={{ fontSize: "16px", lineHeight: "22px" }}>
          All your investment accounts are tracked on one platform You can find
          out how changing market conditions can impact your investment. Easily
          track investment fees on a single dashboard. Discover true market
          returns.
        </p>
        <p style={{ fontSize: "16px", lineHeight: "22px" }}>
          Get insight into your investments and maximize your returns With
          pivotalERP's accounting software, you can transfer money between
          accounts efficiently. With stock watch lists, you can monitor
          investment opportunities Analyze ROI with performance management
          reports and keep an eye on your goal achievement.
        </p>

        <br />
        <p style={{ fontSize: "16px", lineHeight: "22px" }}>
          With the evolving business landscape, the best time to start looking
          and implement a new ERP solution is right now. Pivotal Erp has some of
          the best cloud ERP software solutions equipped with advanced features
          that can help you improve the operational efficiency of your company
          and drive growth. Why not get in touch with us for a free demo or
          consultation to have a better understanding of our products and their
          utility.
        </p>
      </div>
    ),
  };

  return (
    <>
      <Container className="py-md-0 py-4">
        <Row className="align-items-center justify-content-center">
          <Col sm={6}>
            <div className="d-flex mb-4">
              <EllipsisText
                lineClamp="1"
                style={{ background: "#0D97D6", textTransform: "uppercase" }}
                className="px-4 py-1"
                color="#fff"
                fontSize="24px"
                fontWeight="600"
              >
                {blogdetail.category}
              </EllipsisText>
            </div>

            <TitleText fontWeight="500" color="#77BD4B">
              {blogdetail?.title}
            </TitleText>
          </Col>
          <Col sm={6}>
            <img
              src={blogdetail?.image}
              alt={blogdetail?.title}
              style={{ objectFit: "cover" }}
              width="100%"
              height="300px"
            ></img>
          </Col>
          <Col sm={12} className="my-4">
            <div className="d-flex justify-content-start align-items-center">
              <div className="d-flex px-3 py-1">
                <UserIcon />
                <PrimaryText fontSize="18px" color="#0d97d6">
                  {blogdetail?.author?.name}
                </PrimaryText>
              </div>
              <div className="d-flex px-3 py-1">
                <Calendar />
                <PrimaryText fontSize="18px" color="#0d97d6">
                  {blogdetail?.date.day} {blogdetail?.date.month}{" "}
                  {blogdetail?.date.year}
                </PrimaryText>
              </div>
            </div>
          </Col>
          <Col
            sm={12}
            className="d-flex justify-content-center align-items-center"
          >
            <SecondaryText fontSize="18px" fontWeight="500">
              Share :
            </SecondaryText>
            <div className="d-flex justify-content-around align-items-center w-25">
              <Facebook />
              <Instagram />
              <Twitter />
              <Linkedin />
            </div>
          </Col>
        </Row>

        <Row className="mt-4 justify-content-between">
          <Col lg={8}>
            <div className="mt-4">
              <div className="blogParagraph">{blogdetail?.description}</div>
            </div>
          </Col>
          <Col lg={4} className=" ps-lg-5" style={{ minWidth: "320px" }}>
            <TitleText className="m-0">Latest Blogs</TitleText>

            <div>
              {blogLists.map((blog) => {
                if (blog.id !== blogdetail?.id) {
                  return (
                    <div className="d-flex my-3" key={blog.id}>
                      <img src={blog.image} alt="" height="75px" width="75px" />
                      <div className="ms-3 d-flex flex-column justify-content-start">
                        <EllipsisText
                          lineClamp="3"
                          fontWeight="450"
                          fontSize="16px"
                        >
                          {blog.title}
                        </EllipsisText>
                        {/* <div className="mt-auto d-flex">
                          <img src={icon} alt="" width="20px" />
                          <p className="m-0" style={{ fontSize: "12px" }}>
                            {blog?.date.day} {blog?.date.month}{" "}
                            {blog?.date.year}
                          </p>
                        </div> */}
                        <Button
                          className="my-1 text-start"
                          background="transparent"
                          padding="0px"
                          color="#000"
                          onClick={() => navigate("/blogdetail")}
                          border="none"
                        >
                          Read More
                          <svg
                            width="12"
                            height="9"
                            viewBox="0 0 12 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.1113 4.79276C11.3066 4.5975 11.3066 4.28092 11.1113 4.08566L7.92933 0.903675C7.73407 0.708413 7.41749 0.708413 7.22223 0.903675C7.02696 1.09894 7.02696 1.41552 7.22223 1.61078L10.0507 4.43921L7.22223 7.26764C7.02696 7.4629 7.02696 7.77948 7.22223 7.97474C7.41749 8.17001 7.73407 8.17001 7.92933 7.97474L11.1113 4.79276ZM0.656006 4.93921H10.7578V3.93921H0.656006V4.93921Z"
                              fill="#0D97D6"
                            />
                          </svg>
                        </Button>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <DemoCard />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export const DemoCard = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div
      style={{
        position: "sticky",
        top: "90px",
        borderRadius: "12px",
        overflow: "hidden",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
      }}
      className="mt-5"
    >
      <div>
        <div
          style={{ background: "#0D97D6", minHeight: "80px" }}
          className="d-flex justify-content-center align-items-center"
        >
          <TitleText color="#fff">Get A Demo</TitleText>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-2">
          <Row>
            <Col
              sm={12}
              className="px-md-5 px-4 d-flex justify-content-center align-items-center flex-column"
            >
              <div className="w-100">
                <Input
                  border="none"
                  placeholder="Name*"
                  {...register("name", {
                    required: "Please enter name",
                    pattern: {
                      value: /^[A-Z ]+[A-Z]{2,30}$/i,
                      message: "invalid name",
                    },
                  })}
                />
                {errors?.name && errors?.name?.message && (
                  <ErrorText>{errors?.name?.message}</ErrorText>
                )}
              </div>
              <div className="w-100">
                <Input
                  border="none"
                  placeholder="Company Name*"
                  {...register("companyName", {
                    required: "Please enter Company name",
                    pattern: {
                      value: /^[A-Z ]+[A-Z]{2,30}$/i,
                      message: "invalid name",
                    },
                  })}
                />
                {errors?.companyName && errors?.companyName?.message && (
                  <ErrorText>{errors?.companyName?.message}</ErrorText>
                )}
              </div>
              <div className="w-100">
                <Input
                  border="none"
                  placeholder="Email*"
                  {...register("email", {
                    required: "Please enter email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Please enter a valid email",
                    },
                  })}
                />
                {errors?.email && errors?.email?.message && (
                  <ErrorText>{errors?.email?.message}</ErrorText>
                )}
              </div>
              <div className="w-100">
                <Input
                  border="none"
                  placeholder="Phone Number*"
                  {...register("phoneNumber", {
                    required: true,
                    maxLength: {
                      value: 16,
                      message: "Please enter valid number",
                    },
                    minLength: {
                      value: 7,
                      message: "Please enter valid number",
                    },
                  })}
                />
                {errors?.phoneNumber && errors?.phoneNumber?.message && (
                  <ErrorText>{errors?.phoneNumber?.message}</ErrorText>
                )}
              </div>
              <div className="mt-4">
                <SecondaryText>
                  Are you using any accounting ERP already ?
                </SecondaryText>
                <div className="d-flex  align-items-center  p-1 mt-2">
                  <div className="d-flex  align-items-center me-2">
                    <input
                      style={{
                        height: "12px",
                        width: "12px",
                        marginRight: "8px",
                      }}
                      value={true}
                      type="radio"
                      aria-label="Radio for following text input"
                      {...register("alreadyMember", {})}
                    />
                    <p className="p-0 m-0">Yes</p>
                  </div>
                  <div className="d-flex  align-items-center">
                    <input
                      style={{
                        height: "12px",
                        width: "12px",
                        marginRight: "8px",
                      }}
                      value={false}
                      type="radio"
                      aria-label="Radio for following text input"
                      {...register("alreadyMember", {})}
                    />
                    <p className="p-0 m-0">No</p>
                  </div>
                </div>
              </div>

              <Button background="#0D97D6" className="my-4" padding="6px 12px">
                <PrimaryText color="#fff" className="m-0">
                  Request A Demo
                </PrimaryText>
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
};

export const blogLists = [
  {
    id: 2,
    title: "PivotalERP help to rise up business ",
    date: "2022/03/18",
    image: upImage,
    author: "Dev",
    description:
      "We provide sales enquiry management software that allows you to track enquiry status as well as update closing reasons for each enquiry. As a result of these insights, you will be able to make better decisions in the future. Your selling strategies can be improved by recording details for each enquiry closure.",
  },
  {
    id: 3,
    title: "PivotalERP help to rise up business ",
    date: "2022/03/18",
    image: upImage,
    author: "Dev",
    description:
      "We provide sales enquiry management software that allows you to track enquiry status as well as update closing reasons for each enquiry. As a result of these insights, you will be able to make better decisions in the future. Your selling strategies can be improved by recording details for each enquiry closure.",
  },
  {
    id: 4,
    title: "PivotalERP help to rise up business ",
    date: "2022/03/18",
    image: upImage,
    author: "Dev",
    description:
      "We provide sales enquiry management software that allows you to track enquiry status as well as update closing reasons for each enquiry. As a result of these insights, you will be able to make better decisions in the future. Your selling strategies can be improved by recording details for each enquiry closure.",
  },
];
