import "./App.css";
import { Home } from "./component/Home";
import { NavBar } from "./component/navbar/Navbar";
import WaveBackground from "./assets/images/wave.png";
import { HeaderBackground } from "./styledComponent/styleComponent";
import { useLocation } from "react-router-dom";

import { Navigate, Route, Routes } from "react-router-dom";
import { Features } from "./component/Features";
import { Plans } from "./component/PricingSolution";
import { Accountant } from "./component/accountant/Accountant";
import { Support } from "./component/Support";
import { Contact } from "./component/ContactUs";
import { PlatformManagement } from "./component/PlatformManagement";
import { PreferredCondition } from "./component/PreferredConditions";
import { ChoseUs } from "./component/ChoseUs";
import { BankingFund } from "./component/BankingFund";
import { AmountIncurred } from "./component/AmountIcurred";
import { Stocks } from "./component/Stocks";
import { Footer } from "./component/Footer";
import { useRef } from "react";
import { Demo } from "./component/RequestDemo";
import { BlogDetail } from "./component/blogs/BlogDetails";
const useScroll = () => {
  const elRef = useRef(null);
  const executeScroll = () =>
    elRef?.current?.scrollIntoView({
      behavior: "smooth",
      scrollIntoViewOptions: { block: "top", inline: "top" },
    });

  return [executeScroll, elRef];
};

function App() {
  const [executeScroll, elRef] = useScroll();
  const location = useLocation();
  const isBlogDetail = location.pathname === "/blogdetail";

  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home elRef={elRef} />}></Route>
        <Route path="/features" element={<Features />}></Route>
        <Route path="/plans" element={<Plans />}></Route>
        <Route path="/accountant" element={<Accountant />}></Route>
        <Route path="/support" element={<Support />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route
          path="/platform-managing"
          element={<PlatformManagement />}
        ></Route>
        <Route path="/blogDetail" element={<BlogDetail />}></Route>
        <Route
          path="/features/creating-invoices"
          element={<PlatformManagement />}
        ></Route>
        <Route
          path="/preferred-conditions-business"
          element={<PreferredCondition />}
        ></Route>
        <Route
          path="/features/latest-insights"
          element={<PreferredCondition />}
        ></Route>
        <Route path="/why-chose-us" element={<ChoseUs />}></Route>
        <Route path="/features/banking-funds" element={<BankingFund />}></Route>
        <Route
          path="/features/ammount-incurred"
          element={<AmountIncurred />}
        ></Route>
        <Route path="/features/stocks" element={<Stocks />}></Route>
        <Route path="/request-a-demo" element={<Demo />}></Route>
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <Footer executeScroll={executeScroll} />
      <HeaderBackground style={{ display: isBlogDetail ? "none" : "block" }}>
        <img src={WaveBackground} alt="" height="100%" width="100%" />
      </HeaderBackground>
    </div>
  );
}

export default App;
