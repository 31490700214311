import { Col, Container, Row } from "react-bootstrap";
import logo from "../assets/pivotalerplogo.png";

import { useNavigate } from "react-router-dom";
import {
  CopyRightText,
  FooterText,
  SecondaryText,
} from "../styledComponent/styleComponent";
import { Location, MailIcon, PhoneIcon } from "../assets/svg/IconSvg";
import "../styledComponent/wave.css";

export const Footer = ({ executeScroll }) => {
  const navigateTo = useNavigate();

  return (
    <>
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          background: "#0082C6",
        }}
        className="mt-5"
      >
        <Container className="pt-3">
          <Row>
            <Col sm style={{ minWidth: "280px", zIndex: "9" }}>
              <img src={logo} alt="" height="80px" />
              <div className="mt-4">
                <SecondaryText color="#fff">
                  PivotalERP's invoice acquisition software. Your individual
                  follow-up emails can all be sent with PivotalERP.
                </SecondaryText>
              </div>
            </Col>
            <Col sm style={{ minWidth: "200px", zIndex: "9" }}>
              <p
                style={{
                  fontSize: "24px",
                  color: "white",
                  marginBottom: "16px",
                }}
              >
                Quick links
              </p>
              <FooterText
                onClick={() => {
                  navigateTo("/features");
                  window.scrollTo(0, 0);
                }}
              >
                Features
              </FooterText>
              <FooterText
                onClick={() => {
                  navigateTo("/plans");
                  window.scrollTo(0, 0);
                }}
              >
                Plans
              </FooterText>
              <FooterText
                onClick={() => {
                  navigateTo("/accountant");
                  window.scrollTo(0, 0);
                }}
              >
                Accountant
              </FooterText>
              <FooterText
                onClick={() => {
                  navigateTo("/support");
                  window.scrollTo(0, 0);
                }}
              >
                Support
              </FooterText>
            </Col>
            <Col sm style={{ zIndex: "9", minWidth: "200px" }}>
              <p
                style={{
                  fontSize: "24px",
                  color: "white",
                  marginBottom: "16px",
                }}
              >
                Features
              </p>
              <FooterText
                onClick={() => {
                  navigateTo("/features/creating-invoices");
                  window.scrollTo(0, 0);
                }}
              >
                Creating invoices
              </FooterText>
              <FooterText
                onClick={() => {
                  navigateTo("/features/ammount-incurred");
                  window.scrollTo(0, 0);
                }}
              >
                Amounts incurred
              </FooterText>
              <FooterText
                onClick={() => {
                  navigateTo("/features/banking-funds");
                  window.scrollTo(0, 0);
                }}
              >
                Banking & Funds
              </FooterText>
              <FooterText
                onClick={() => {
                  navigateTo("/features/stocks");
                  window.scrollTo(0, 0);
                }}
              >
                Stocks
              </FooterText>
              <FooterText
                onClick={() => {
                  navigateTo("/features/latest-insights");
                  window.scrollTo(0, 0);
                }}
              >
                Latest Insights
              </FooterText>
            </Col>
            <Col sm style={{ zIndex: "9", minWidth: "200px" }}>
              <p
                style={{
                  fontSize: "24px",
                  color: "white",
                  marginBottom: "16px",
                }}
              >
                Help
              </p>
              <FooterText
                onClick={() => {
                  navigateTo("/support");
                  window.scrollTo(0, 0);
                }}
              >
                Support
              </FooterText>
              <FooterText
                onClick={() => {
                  navigateTo("/contact");
                  window.scrollTo(0, 0);
                }}
              >
                Contact Us
              </FooterText>
              <FooterText
                onClick={async () => {
                  navigateTo("/");
                  executeScroll();
                }}
              >
                FAQ
              </FooterText>
            </Col>
            <Col sm style={{ zIndex: "9", minWidth: "260px" }}>
              <p
                style={{
                  fontSize: "24px",
                  color: "white",
                  marginBottom: "16px",
                }}
              >
                Information
              </p>
              <FooterText>
                <PhoneIcon /> +977-9801087091
              </FooterText>
              <FooterText>
                <MailIcon /> info@pivotalerp.app
              </FooterText>
              <FooterText>
                <Location /> Birgunj Parsa, Nepal
              </FooterText>
            </Col>
          </Row>
        </Container>
        <div>
          <div
            style={{
              textAlign: "center",
              minHeight: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#003F5C",
              zIndex: "999",
            }}
          >
            <CopyRightText>© Copyright 2022, pivotalerp</CopyRightText>
          </div>
        </div>
        <div className="container-fliud">
          <div className="ocean">
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
        </div>
      </div>
    </>
  );
};
