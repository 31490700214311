import { Header } from "./HeaderSection";
import contactImage from "../assets/svg/contactImage.svg";
import { Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  Button,
  ContactFomContainer,
  ErrorText,
  Input,
  LinkText,
  PrimaryText,
  TextArea,
} from "../styledComponent/styleComponent";
import { Fade } from "react-reveal";
import GoogleMaps from "./map/Map";

export const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div className="mb-5">
      <Header
        title="Get In Touch"
        subtitle="Contact us for any support or business related queries."
        image={contactImage}
      />

      <Container className="mt-5">
        <Row className="g-4 m-0">
          <Col lg={6} className="p-0">
            <ContactFomContainer>
              <Fade up delay={100}>
                {" "}
                <PrimaryText color="#0D97D6" className="mb-4">
                  Drop a message
                </PrimaryText>
              </Fade>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col>
                    <Fade up delay={200}>
                      {" "}
                      <Input
                        placeholder="Organization Name"
                        {...register("organizationName", {
                          required: "Please enter organization name",
                          pattern: {
                            value: /^[A-Z ]+[A-Z]{2,30}$/i,
                            message: "invalid name",
                          },
                        })}
                      />
                    </Fade>

                    {errors?.organizationName &&
                      errors?.organizationName?.message && (
                        <ErrorText>
                          {errors?.organizationName?.message}
                        </ErrorText>
                      )}
                  </Col>
                  <Col>
                    <Fade up delay={200}>
                      <Input
                        placeholder="Email"
                        {...register("email", {
                          required: "Please enter email",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Please enter a valid email",
                          },
                        })}
                      />
                    </Fade>

                    {errors?.email && errors?.email?.message && (
                      <ErrorText>{errors?.email?.message}</ErrorText>
                    )}
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col>
                    <Fade up delay={300}>
                      <Input
                        placeholder="Phone Number"
                        {...register("phoneNumber", {
                          required: false,
                          maxLength: {
                            value: 16,
                            message: "Please enter valid number",
                          },
                          minLength: {
                            value: 7,
                            message: "Please enter valid number",
                          },
                        })}
                      />
                    </Fade>

                    {errors?.phoneNumber && errors?.phoneNumber?.message && (
                      <ErrorText>{errors?.phoneNumber?.message}</ErrorText>
                    )}
                  </Col>
                  <Col>
                    <Fade up delay={300}>
                      <Input
                        placeholder="Subject"
                        {...register("subject", {
                          required: "Please enter Subject",
                          pattern: {
                            value: /^[A-Z ]+[A-Z]{2,30}$/i,
                            message: "invalid Charecter",
                          },
                        })}
                      />
                    </Fade>

                    {errors?.subject && errors?.subject?.message && (
                      <ErrorText>{errors?.subject?.message}</ErrorText>
                    )}
                  </Col>
                </Row>
                <Fade up delay={350}>
                  <TextArea
                    className="mt-4"
                    placeholder="Message"
                    {...register("message", {
                      minLength: 3,
                    })}
                    rows="3"
                  />
                </Fade>

                <Fade right delay={100}>
                  <Button
                    background="#009CEA"
                    padding=" 5px 22px"
                    className="mt-3"
                  >
                    <LinkText color="#fff">Send</LinkText>
                  </Button>
                </Fade>
              </form>
            </ContactFomContainer>
          </Col>
          <Col lg={6}>
            <GoogleMaps />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
