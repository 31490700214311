import { Header } from "./HeaderSection";
import mockUp from "../assets/images/mockup.png";
import { Col, Container, Row } from "react-bootstrap";
import {
  Button,
  ContactFomContainer,
  ErrorText,
  Input,
  LinkText,
  PrimaryText,
  SecondaryText,
  TextArea,
} from "../styledComponent/styleComponent";
import { Fade } from "react-reveal";
import { useForm } from "react-hook-form";

export const Demo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <>
      <Header
        title="Request a Demo"
        subtitle="Looking for more information about Pivotal ERP? We regularly save account managers days of time each month with our automated accounting software. We offer complimentary demos to demonstrate how our accounting software can manage your business and help you get away from old technology."
        image={mockUp}
      />
      <Container className="mt-4 mb-5">
        <Row className="g-4 m-0 justify-content-center">
          <Col lg={6} className="p-0">
            <ContactFomContainer>
              <Fade up delay={100}>
                <PrimaryText color="#0D97D6" className="mb-4">
                  Demo Request Form
                </PrimaryText>
              </Fade>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col>
                    <Fade up delay={200}>
                      <Input
                        placeholder="Name"
                        {...register("name", {
                          required: "Please enter name",
                          pattern: {
                            value: /^[A-Z ]+[A-Z]{2,30}$/i,
                            message: "invalid name",
                          },
                        })}
                      />
                    </Fade>

                    {errors?.organizationName &&
                      errors?.organizationName?.message && (
                        <ErrorText>
                          {errors?.organizationName?.message}
                        </ErrorText>
                      )}
                  </Col>
                  <Col>
                    <Fade up delay={200}>
                      <Input
                        placeholder="Email"
                        {...register("email", {
                          required: "Please enter email",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Please enter a valid email",
                          },
                        })}
                      />
                    </Fade>

                    {errors?.email && errors?.email?.message && (
                      <ErrorText>{errors?.email?.message}</ErrorText>
                    )}
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col>
                    <Fade up delay={300}>
                      <Input
                        placeholder="Phone Number"
                        {...register("phoneNumber", {
                          required: false,
                          maxLength: {
                            value: 16,
                            message: "Please enter valid number",
                          },
                          minLength: {
                            value: 7,
                            message: "Please enter valid number",
                          },
                        })}
                      />
                    </Fade>

                    {errors?.phoneNumber && errors?.phoneNumber?.message && (
                      <ErrorText>{errors?.phoneNumber?.message}</ErrorText>
                    )}
                  </Col>
                  <Col>
                    <Fade up delay={300}>
                      <Input
                        placeholder="Designation"
                        {...register("designation", {
                          required: "Please enter Designation",
                          pattern: {
                            value: /^[A-Z ]+[A-Z]{2,30}$/i,
                            message: "invalid Charecter",
                          },
                        })}
                      />
                    </Fade>

                    {errors?.subject && errors?.subject?.message && (
                      <ErrorText>{errors?.subject?.message}</ErrorText>
                    )}
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Col>
                      <Fade up delay={300}>
                        <Input
                          placeholder="Address"
                          {...register("address", {
                            required: "Please enter Address",
                            pattern: {
                              value: /^[A-Z ]+[A-Z]{2,30}$/i,
                              message: "invalid Charecter",
                            },
                          })}
                        />
                      </Fade>

                      {errors?.subject && errors?.subject?.message && (
                        <ErrorText>{errors?.subject?.message}</ErrorText>
                      )}
                    </Col>
                  </Col>
                </Row>

                <Fade up delay={350}>
                  <div className="mt-4">
                    <SecondaryText>
                      Are you using any accounting ERP ( Enterprise Resource
                      Planning) already ?
                    </SecondaryText>
                    <div className="d-flex  align-items-center  p-1 mt-2">
                      <div className="d-flex  align-items-center me-2">
                        <input
                          style={{
                            height: "12px",
                            width: "12px",
                            marginRight: "8px",
                          }}
                          value={true}
                          type="radio"
                          aria-label="Radio for following text input"
                          {...register("alreadyMember", {})}
                        />
                        <p className="p-0 m-0">Yes</p>
                      </div>
                      <div className="d-flex  align-items-center">
                        <input
                          style={{
                            height: "12px",
                            width: "12px",
                            marginRight: "8px",
                          }}
                          value={false}
                          type="radio"
                          aria-label="Radio for following text input"
                          {...register("alreadyMember", {})}
                        />
                        <p className="p-0 m-0">No</p>
                      </div>
                    </div>
                  </div>
                </Fade>
                <Fade up delay={350}>
                  <TextArea
                    className="mt-4"
                    placeholder="Message"
                    {...register("message", {
                      minLength: 3,
                    })}
                    rows="3"
                  />
                </Fade>

                <Fade right delay={100}>
                  <Button
                    background="#009CEA"
                    padding=" 5px 22px"
                    className="mt-3"
                  >
                    <LinkText color="#fff">Send A Request</LinkText>
                  </Button>
                </Fade>
              </form>
            </ContactFomContainer>
          </Col>
        </Row>
      </Container>
    </>
  );
};
