import { Header } from "./HeaderSection";
import { PricingSolution } from "./Home";
import plansImage from "../assets/svg/plans.svg";

export const Plans = () => (
  <>
    <Header
      title="PivotalERP has a solution for every business"
      image={plansImage}
    />
    <PricingSolution title="PivotalERP has a solution for you" />
  </>
);
