import { Header } from "./HeaderSection";
import featureImage from "../assets/svg/menWithCalculator.svg";
import invoices from "../assets/svg/invoices.svg";
import bearish from "../assets/svg/bearish.svg";
import bank from "../assets/svg/bank.svg";
import receipt from "../assets/svg/receipt.svg";
import analysisWithLens from "../assets/svg/analysisWithLens.svg";
import analysis from "../assets/svg/analysis.svg";

import { Col, Container, Row } from "react-bootstrap";
import {
  Button,
  FeaturesColumn,
  LinkText,
  PrimaryText,
  SecondaryText,
  TitleText,
} from "../styledComponent/styleComponent";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";

export const Features = () => {
  return (
    <>
      <Header
        title="Features that help you run your business"
        image={featureImage}
      />
      <Container className="mt-5 mb-5">
        <Fade up delay={100}>
          <TitleText>Some Features of pivotalERP</TitleText>
        </Fade>
        <SideBySide
          title="Creating invoices"
          subTitle="Make business solutions that benefit from these features, such as voice notifications,invoice reminders, and automated synchronization of payments to receipts."
          path="/features/creating-invoices"
          rightImage={true}
          image={invoices}
        />
        <SideBySide
          title="Amounts incurred"
          subTitle="You can consolidate all your expenses under one roof and prepare for tax season."
          path="/features/ammount-incurred"
          image={bearish}
          rightImage={false}
        />
        <SideBySide
          title="Banking & Funds"
          subTitle="You can get a complete picture of your finances when you connect your bank."
          path="/features/banking-funds"
          image={bank}
          rightImage={true}
        />
        <SideBySide
          title="Stocks"
          subTitle="Maintain real-time stock control by keeping an eye on your orders and stock levels."
          path="/features/stocks"
          image={analysis}
          rightImage={false}
        />
        <SideBySide
          title="Receipts are collected and organized"
          subTitle="
          Take pictures of receipts using your phone. Using PivotalERP, you can stay on top of everything, keep track of everything, and stay ready for taxes."
          path=""
          image={receipt}
          rightImage={true}
        />
        <SideBySide
          title="A look at the latest insights"
          subTitle=" View popular reports, and a cash flow calculator, get an overview of your company's performance and get a sense of the future."
          path="/features/latest-insights"
          image={analysisWithLens}
          rightImage={false}
        />
      </Container>
    </>
  );
};

export const SideBySide = ({ title, subTitle, path, rightImage, image }) => {
  const navigate = useNavigate();

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center g-3 mt-3">
        <Col
          sm={6}
          className={`justify-content-center p-0 d-flex ${
            rightImage ? `justify-content-sm-end` : `justify-content-start`
          } align-items-center ${rightImage ? `order-1 order-sm-2` : ``}`}
        >
          {rightImage ? (
            <Fade right delay={200}>
              <img src={image} alt="" height="180px" />
            </Fade>
          ) : (
            <Fade left delay={200}>
              <img src={image} alt="" height="180px" />
            </Fade>
          )}
        </Col>
        <FeaturesColumn
          sm={6}
          className={`p-4 ${rightImage ? `order-2 order-sm-1` : ``} `}
          rightImage={rightImage}
        >
          <Fade up delay={100}>
            <PrimaryText>{title}</PrimaryText>
          </Fade>
          <Fade up delay={200}>
            <SecondaryText>{subTitle}</SecondaryText>
            <>
              {path !== "" && (
                <Button
                  background="#009CEA"
                  padding=" 4px 20px"
                  className="mt-4"
                  onClick={() => {
                    navigate(path);
                    window.scrollTo(0, 0);
                  }}
                >
                  <LinkText color="#fff">Learn More</LinkText>
                </Button>
              )}
            </>
          </Fade>
        </FeaturesColumn>
      </Row>
    </>
  );
};
