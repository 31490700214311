import { Header } from "../HeaderSection";
import accountant from "../../assets/svg/accountant.svg";
import { Col, Container, Row } from "react-bootstrap";
import plans from "../../assets/svg/plans.svg";
import monitorBackground from "../../assets/svg/monitorBackground.svg";
import bookWithAnalytical from "../../assets/svg/bookWithAnalytical.svg";

import {
  Button,
  LinkText,
  PointerDiv,
  PrimaryText,
  SecondaryText,
  TitleText,
} from "../../styledComponent/styleComponent";
// import { LineArrow } from "../../assets/svg/IconSvg";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";

export const Accountant = () => {
  const navigateTo = useNavigate();
  return (
    <>
      <Header
        height="70%"
        title="Reduce the burden of managing your company"
        image={accountant}
        subtitle="You can access real-time information anywhere, anytime with PivotalERP, a powerful accounting solution for small businesses. An on-ramp to the cloud has never been easier thanks to industry-leading support and training included."
      />
      <Container className="mt-5">
        <Row className="d-flex justify-content-between align-items-center">
          <Col md={5} className="ps-0">
            <Fade left delay={100}>
              <img
                src={plans}
                alt=""
                width="100%"
                height="100%"
                style={{ minWidth: "300px" }}
              ></img>
            </Fade>
          </Col>
          <Col md={6} className="pe-0 mt-md-0 mt-4">
            <Fade top delay={100}>
              <TitleText>
                Our accounting web-based application is tailored to help you
                succeed
              </TitleText>
            </Fade>
            <Fade top delay={150}>
              <SecondaryText>
                Being a bookkeeper or accountant can be tough. Streamlining
                workflows, making client data accessible anywhere, & freeing up
                time so you can dedicate that time to high-value & profitable
                activities will ease your tasks. PivotalERP Online will help you
                accomplish all of this.
              </SecondaryText>
            </Fade>

            <div className="mt-3">
              <Fade top delay={100}>
                <PointerDiv>
                  <PrimaryText>In any location, on any schedule</PrimaryText>
                  <SecondaryText>
                    Access all your client's books anywhere, anytime, and
                    streamline productivity for both you & your client.
                  </SecondaryText>
                </PointerDiv>
              </Fade>
              <Fade top delay={150}>
                <PointerDiv borderRadius="0px">
                  <PrimaryText>Ensure your security</PrimaryText>
                  <SecondaryText>
                    We use high-tech security measures to protect the
                    information of our clients.
                  </SecondaryText>
                </PointerDiv>
              </Fade>
              <Fade top delay={200}>
                <PointerDiv height="17px" borderRadius="0px">
                  <PrimaryText>
                    Continuity of service with no charge
                  </PrimaryText>
                  <SecondaryText>
                    Stay up to date on your books by pairing them with your bank
                    and favorite apps.
                  </SecondaryText>
                </PointerDiv>
              </Fade>
            </div>
          </Col>
        </Row>
      </Container>
      <div
        className="mt-5 w-100 py-4"
        style={{
          background:
            "linear-gradient(115.23deg, #0D97D6 -1.74%, #0D97D6 30.97%, #74B750 66.13%)",
        }}
      >
        <Container>
          <Row className="gy-4">
            <Col sm={6} md={6} className="order-md-1 order-2">
              <Fade up delay={100}>
                <TitleText color="#fff">
                  Have you experienced PivotalERP Online yet?
                </TitleText>
              </Fade>
              <Fade up delay={200}>
                <Button
                  background="#fff"
                  padding=" 5px 22px"
                  className="mt-3"
                  onClick={() => navigateTo("/request-a-demo")}
                >
                  <LinkText color="#009CEA">Request a demo</LinkText>
                </Button>
              </Fade>
            </Col>
            <Col
              sm={6}
              md={6}
              className="order-md-2 order-1 d-flex justify-content-md-end justify-content-center align-items-center "
            >
              <Fade right delay={100}>
                <img src={monitorBackground} alt="" height="260px"></img>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <SubContainer
          title="Provide clients with financial information about income, resources, and expenses"
          subtitle=" Monitoring the cost of a project is so easy with PivotalERP Online projects. It's easy to keep track of project costs revenue, duration, and resources. Tracking the expenses of everyone involved in a project can ensure that all costs have beeincurred.Your clients can use the materials report to track the cost of parts, supplies, and other tangible assets. It is essential to factor in their fixed practices when projecting costs in order to maintain project profitability."
          image={plans}
          rightImage={true}
        />
        <SubContainer
          title="Inform others about valuable insights"
          subtitle="Take advantage of PivotalERP online to access financial data and accountingreports in an instant. Your business decisions are always in the right hands with smart insights available at a click. With powerful reporting, you can view sales data by client, product, assignment, or region. Your customers need the right data to conduct successful business. Graphs and dashboards are used to present data in real-time. This provides a thorough overview of revenue and expenses throughout the year."
          image={plans}
          rightImage={false}
        />
        <SubContainer
          title="You can accelerate your productivity with a variety of additional apps"
          subtitle="You can enhance the accounting processes of your practice or client with PivotalERP third-party integrations. Your technology solution can be enhanced effortlessly with the extensive selection of apps. In addition to accounting, supply chain management, expenditures, human resource management, and sales, the apps offer a variety of benefits."
          image={plans}
          rightImage={true}
        />
      </Container>
      {/* <GradientBox title="Check out PivotalERP Online to see what it can offer you" /> */}
    </>
  );
};

export const SubContainer = ({
  title,
  subtitle,
  image,
  rightImage,
  button,
  lists,
}) => {
  const navigateTo = useNavigate();
  return (
    <Row className="d-flex justify-content-between align-items-center gy-3 mt-4">
      <Col
        md={5}
        style={{ minWidth: "290px" }}
        className={`d-flex ${
          rightImage ? `justify-content-sm-end` : `justify-content-start`
        } align-items-center ${rightImage ? `order-1 order-sm-2` : ``}`}
      >
        {rightImage ? (
          <Fade right delay={100}>
            <img
              src={image}
              alt=""
              width="100%"
              height="100%"
              style={{ minWidth: "280px", maxHeight: "250px" }}
            ></img>
          </Fade>
        ) : (
          <Fade left delay={100}>
            <img
              src={image}
              alt=""
              width="100%"
              height="100%"
              style={{ minWidth: "280px", maxHeight: "250px" }}
            ></img>
          </Fade>
        )}
      </Col>
      <Col md={6} className={` ${rightImage ? `order-2 order-sm-1` : ``} `}>
        <Fade up delay={100}>
          <TitleText>{title}</TitleText>
        </Fade>
        <Fade up delay={150}>
          <SecondaryText>{subtitle}</SecondaryText>
        </Fade>
        <Fade up delay={200}>
          <>
            {button && (
              <Button
                background="#009CEA"
                padding=" 5px 22px"
                className="mt-3"
                onClick={() => navigateTo("/request-a-demo")}
              >
                <LinkText color="#fff">Request a demo</LinkText>
              </Button>
            )}
            {lists && lists}
          </>
        </Fade>
      </Col>
    </Row>
  );
};

export const GradientBox = ({ title }) => {
  const navigateTo = useNavigate();
  return (
    <div
      style={{
        background:
          "linear-gradient(115.23deg,#0d97d6 -1.74%,#0d97d6 30.97%,#74b750 66.13%)",
        minHeight: "350px",
        width: "100%",
      }}
      className="mt-5"
    >
      <Row className="m-0 justify-content-between">
        <Col
          sm={5}
          style={{
            background: "#F7F7F7",
            marginTop: "20px",
            minHeight: "340px",
            borderRadius: "0px 190px 0px 0px",
          }}
          className={`p-0 d-flex justify-content-start align-items-center `}
        >
          <Fade left delay={100}>
            <img
              src={bookWithAnalytical}
              alt=""
              height="80%"
              width="90%"
              style={{ maxHeight: "330px" }}
            />
          </Fade>
        </Col>

        <Col
          sm={6}
          className={`p-0 d-flex justify-content-center flex-column align-items-center `}
        >
          <Container>
            <Fade up delay={100}>
              <TitleText color="#fff">{title}</TitleText>
            </Fade>
            <Fade up delay={200}>
              <Button
                background="#fff"
                padding=" 5px 22px"
                className="mt-3"
                onClick={() => navigateTo("/request-a-demo")}
              >
                <LinkText color="#009CEA">Request a demo</LinkText>
              </Button>
            </Fade>
          </Container>
        </Col>
      </Row>
    </div>
  );
};
