import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  LinkText,
  SecondaryText,
  TitleText,
  GradientBackground,
  PrimaryText,
} from "../styledComponent/styleComponent";

export const Header = ({
  title,
  image,
  subtitle,
  preferredCondition,
  height,
}) => {
  const location = useLocation();
  const navigateTo = useNavigate();
  const isDemo = location.pathname === "/request-a-demo";

  return (
    <GradientBackground>
      <Container>
        <Row className="gy-4">
          <Col
            md={6}
            className="order-md-1 order-2  d-flex justify-content-center flex-column pe-md-5"
          >
            <Fade up delay={100}>
              <TitleText color="#fff" className="text-sm-primary text-md-white">
                {title}
              </TitleText>
            </Fade>
            <Fade up delay={200}>
              {subtitle && (
                <SecondaryText color="#fff">{subtitle}</SecondaryText>
              )}
            </Fade>

            {preferredCondition && (
              <div className="pe-3">
                <Fade up delay={200}>
                  <PrimaryText color="#fff">The invoicing process</PrimaryText>
                </Fade>
                <Fade up delay={250}>
                  <SecondaryText color="#fff">
                    Easily create attractive invoices and send them instantly.
                    Increase your cash flow with personalized invoices and
                    automatic notifications.
                  </SecondaryText>
                </Fade>
                <Fade up delay={200}>
                  <PrimaryText color="#fff">Amounts incurred</PrimaryText>
                </Fade>
                <Fade up delay={250}>
                  <SecondaryText color="#fff">
                    To keep you prepared for tax time, we will keep a record of
                    every receipt. Put anend to all those receipts in your
                    pocket.
                  </SecondaryText>
                </Fade>
              </div>
            )}
            <Fade up delay={300}>
              <Button
                background="#fff"
                padding=" 5px 22px"
                className="mt-3"
                onClick={() => {
                  if (isDemo) {
                    navigateTo("/contact");
                  } else {
                    navigateTo("/request-a-demo");
                  }
                }}
              >
                {isDemo ? (
                  <LinkText color="#009CEA">Contact Us</LinkText>
                ) : (
                  <LinkText color="#009CEA">Request a demo</LinkText>
                )}
              </Button>
            </Fade>
          </Col>
          <Col
            md={6}
            className="order-md-2 order-1 d-flex justify-content-center align-items-center"
            style={{ minWidth: "320px" }}
          >
            <Fade right delay={100}>
              <img
                src={image}
                alt=""
                height="80%"
                width="80%"
                style={{ minWidth: "290px", maxHeight: "600px" }}
              ></img>
            </Fade>
          </Col>
        </Row>
      </Container>
    </GradientBackground>
  );
};
