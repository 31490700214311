import { useState } from "react";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";
import { DashedIcon, PlusIcon } from "../assets/svg/IconSvg";
import { SecondaryText, TitleText } from "../styledComponent/styleComponent";

const Faq = [
  {
    id: 1,
    q: "What is the process for signing up?",
    a: "Getting started with PivotalERP is easy. You can schedule a demo by entering your company name, email address, and phone number by clicking on the Request a Demo button, and you're in business. PivotalERP offers you a fully-functional demo period before you subscribe.",
  },
  {
    id: 2,
    q: "Do I have to stay with PivotalERP forever?",
    a: "Not at all! You are not obligated to sign up for PivotalERP on a long-term basis, since it is a pay-per-use service. Any time you feel our product or service is not working for you, you may cancel your subscription. ",
  },
  {
    id: 3,
    q: "Do my transactions appear to be secure?",
    a: "Yes, it’s fully secured. Pivotal ERP encrypts all data and requires two-factor authentication for your transactions.",
  },
  {
    id: 4,
    q: "Can I run more than one business?",
    a: "Definitely. PivotalERP allows you to add multiple companies to your account, which you can treat as different businesses.",
  },
  {
    id: 5,
    q: "Is the information about my credit card stored on your servers?",
    a: "No, we don’t store your card information on our servers.",
  },
];

export const FaqSection = ({ elRef }) => {
  const [showAnswer, setShowAnswer] = useState(0);

  return (
    <div className="mb-5">
      <Container className="mt-5" ref={elRef}>
        <Fade up delay={100}>
          <SecondaryText>
            Do you have any questions? Check our FAQ or Help Center
          </SecondaryText>
        </Fade>
        <Fade up delay={200}>
          <TitleText>Frequently Asked Questions</TitleText>
        </Fade>
      </Container>
      {Faq.map((query) => (
        <div
          key={query.id}
          style={{
            marginTop: "18px",

            background: "#F7F7F7",
          }}
        >
          <Container>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                minHeight: "60px",
              }}
            >
              <Fade top delay={30 * query.id}>
                <SecondaryText>{query.q}</SecondaryText>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (showAnswer >= 0 && showAnswer !== query.id) {
                      setShowAnswer(query.id);
                    } else {
                      setShowAnswer(0);
                    }
                  }}
                >
                  {showAnswer === query.id ? <DashedIcon /> : <PlusIcon />}
                </div>
              </Fade>
            </div>
            {showAnswer === query.id && (
              <div className="mt-2 pb-3">
                <Fade top delay={100}>
                  <SecondaryText>{query.a}</SecondaryText>
                </Fade>
              </div>
            )}
          </Container>
        </div>
      ))}
    </div>
  );
};
