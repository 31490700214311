import { Header } from "./HeaderSection";
import reportAnalysis from "../assets/svg/reportAnalysis.svg";
import detailReport from "../assets/svg/detailReport.svg";
import { Col, Container, Row } from "react-bootstrap";
import girlsAtTop from "../assets/svg/girlsAttop.svg";
import analytical from "../assets/svg/analytical.svg";
import invoiceBill from "../assets/svg/invoiceBill.svg";
import analysis from "../assets/svg/analysis.svg";
import accountant from "../assets/svg/accountant.svg";
import analysisWithLens from "../assets/svg/analysisWithLens.svg";

import {
  Image,
  ListItemCol,
  SecondaryText,
  TitleText,
} from "../styledComponent/styleComponent";
import { ListItem } from "./PlatformManagement";
import { SubContainer } from "./accountant/Accountant";
import { PricingSolution } from "./Home";
import { useLocation } from "react-router-dom";

export const PreferredCondition = () => {
  const location = useLocation().pathname;

  return (
    <>
      <Header
        title={
          location === "/latest-insights"
            ? "A look at the latest insights"
            : "A single platform for managing everything"
        }
        subtitle={
          location === "/latest-insights"
            ? "View popular reports, and a cash flow calculator, get an overview of your company's performance and get a sense of the future."
            : "Book-keeping can be done from a computer, laptop, tablet, or smartphone. You can create access credentials for your accountant and team to access your data online."
        }
        image={location === "/latest-insights" ? analysisWithLens : girlsAtTop}
      />
      <Container className="mt-5">
        <Row className="gy-4 mt-5 d-flex justify-content-between align-items-center">
          <Col md={6} className="order-2 order-md-1">
            <TitleText>Detailed reports</TitleText>
            <SecondaryText>
              You can view a selection of useful reports to find out how things
              are going. You can also build one with the details that are
              important to you.
            </SecondaryText>
            <ListItemCol>
              <ListItem
                title="The web-based instant financial reporting application PivotalERP"
                subtitle="Reports and financial statements can be accessed instantly. The results you need for your business can be seen just one click away with smart insights."
              />
              <ListItem
                title="Your business is clearly understood with a clear vision"
                subtitle="Organize your data and produce accurate business reports. PivotalERP simplifies the process."
              />
              <ListItem
                title="Identify your company's strengths and weaknesses"
                subtitle="Your sales are visible from all sides. Easily sort them by the client, item, work, or region."
              />
              <ListItem
                title="Reports can be customized"
                subtitle="Make your own individualized financial reports, including profit and loss statements, financial statements, or balance sheets, and customize them accordingly."
              />
            </ListItemCol>
          </Col>
          <Col md={5} className="order-1 order-md-2">
            <Image src={detailReport} alt="" maxHeight="300px" />
          </Col>
        </Row>

        <Row className="gy-4 mt-5 d-flex justify-content-between align-items-center">
          <Col md={5}>
            <Image src={reportAnalysis} alt="" maxHeight="300px" />
          </Col>
          <Col md={6}>
            <TitleText> A description of the details being reported</TitleText>
            <ListItemCol>
              <ListItem
                title="Prepare a profit and loss report"
                subtitle={
                  <>
                    An important tool of every business owner is the profit and
                    loss statement. Profit and loss statements reveal whether a
                    business is profitable or not. <br className="line" /> By
                    examining the profit and loss statement instantly,
                    pivotalERP Online assists you to identify opportunities to
                    decrease costs, generate more revenue and improve
                    profitability
                  </>
                }
              />
              <ListItem
                title="Creating personalized balance sheets"
                subtitle={
                  <>
                    Your business can be identified from one month to the next,
                    or even over the course of a year using pivotalERP.
                    Investments and liabilities can be seen on the balance
                    sheet.
                    <br className="line" />
                    These comprise your cash, assets, liabilities, and deposit
                    balances. Make a point of comparing these figures
                    periodically so you can see where your business is strong
                    and weak, helping you determine how to grow and make
                    investments later.
                  </>
                }
              />
              <ListItem
                title="The company's picture"
                subtitle="The performance of your company can be seen in real-time. PivotalERP provides graphical representations for visualizing income and expenditures dynamically."
              />
            </ListItemCol>
          </Col>
        </Row>
        <SubContainer
          title="An easy way to report on business"
          subtitle="Better business choices can be made with accurate financial information. Your PivotalERP dashboard gives you detailed insights into your company with financial statements such as profit and loss statements, balance sheets, and income statements. Manage your payroll & taxes. Your report configuration will determine which numbers are most relevant to your business."
          rightImage={true}
          image={girlsAtTop}
        />
        <SubContainer
          title="Create a winning environment for yourself"
          subtitle="Put yourself in a position to overcome challenges. Assess current trends to make wise decisions. Ensure investors approve your loan by providing a financial report. "
          rightImage={false}
          image={analytical}
        />
        <SubContainer
          title="Reports customized according to your needs"
          subtitle="Streamline your small business finances by creating and sharing a professional summary with partners. Your accountant will be in the loop with email updates on customizing you reports based on the most relevant data."
          rightImage={true}
          image={invoiceBill}
        />
        <SubContainer
          title="Identify who should be paying you"
          subtitle="The Customer Balances reports and Receivables Summary reports are some accounting reports within PivotalERP that help you manage your outstanding invoices, accounts receivable, & overdue balances."
          rightImage={false}
          image={analysis}
        />
        <SubContainer
          title="Engage the services of an accountant"
          subtitle="Access your accounting reports online and collaborate with your accountant. You will receive personalized financial reports from your accountant specialized to meet the requirements of your business."
          rightImage={true}
          image={accountant}
        />
      </Container>
      {/* <PricingSolution title="You can choose a plan appropriate for your company" /> */}
      {/* <GradientBox title="See how you can benefit from PivotalERP Online" /> */}
    </>
  );
};
