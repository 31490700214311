import { Container } from "react-bootstrap";
import bank from "../assets/svg/bank.svg";
import { SubContainer } from "./accountant/Accountant";
import { Header } from "./HeaderSection";
import bankWithCarts from "../assets/svg/bankWithCards.svg";
import reportAnalysis from "../assets/svg/reportAnalysis.svg";
import analysisWithLens from "../assets/svg/analysisWithLens.svg";
import analysis from "../assets/svg/analysis.svg";
import accountant from "../assets/svg/accountant.svg";
import detailReport from "../assets/svg/detailReport.svg";

export const BankingFund = () => (
  <>
    <Header
      image={bank}
      title="Banking & Funds"
      subtitle="You can get a complete picture of your finances when you connect your bank."
    />
    <Container className="mt-5">
      <SubContainer
        title="Providing better banking accessibility"
        subtitle="Connect your bank to PivotalERP and speed up the process and avoid mistakes.The process takes just a few moments and there is no extra charge."
        rightImage={true}
        image={bankWithCarts}
      />
      <SubContainer
        title="Don't let your money go to waste"
        subtitle="Through PivotalERP's accounting and financial management tools, businesses around Nepal find banking easy. It is important to have a bank account connected to see how your finances are doing."
        rightImage={false}
        image={detailReport}
      />
      <SubContainer
        title="Providing insight right away"
        subtitle="Keep track of what comes in and goes out. Your business performance can be seen in real-time through your account statements."
        rightImage={true}
        image={analysisWithLens}
      />
      <SubContainer
        title="Data will no longer need to be individually entered"
        subtitle="Getting rid of paperwork mistakes and number calculation errors. Make PivotalERP work for you directly from your bank account."
        rightImage={false}
        image={analysis}
      />
      <SubContainer
        title="Don't allow your data to stagnate"
        subtitle="Every transaction appears immediately in PivotalERP Online if bank data is synchronized with the platform."
        rightImage={true}
        image={reportAnalysis}
      />
      <SubContainer
        title="Organizing transactions"
        subtitle="With our automated solution, each transaction is automatically classified based on the income and the expenses associated with it."
        rightImage={false}
        image={accountant}
      />
    </Container>
    {/* <GradientBox title="Learn how PivotalERP can benefit your business." /> */}
  </>
);
