import { Container } from "react-bootstrap";
import {
  PrimaryText,
  SecondaryText,
  TableHeader,
  TitleText,
} from "../styledComponent/styleComponent";
import { Header } from "./HeaderSection";
import supportImage from "../assets/svg/supportImage.svg";
import { Fade } from "react-reveal";

export const Support = () => {
  const supportDetails = [
    { location: "Kathmandu", sales: "01-422826", support: "9851163652", id: 1 },
    { location: "Butwal", sales: "9857030577", support: "9857030577", id: 2 },
    {
      location: "Birgunj",
      sales: "051-526647/48",
      support: "9855021231",
      id: 3,
    },
  ];
  return (
    <>
      <Header
        image={supportImage}
        title="PivotalERP Customer Support"
        subtitle="You can contact us at any time for support. We value our customers more than anything and put our best to provide you with the best quality support."
      />
      <Container className="mt-5 mb-3">
        <Fade up delay={100}>
          <TitleText>Location Wise Sales & Support Details</TitleText>
        </Fade>
      </Container>

      <div
        style={{
          background: "#F1F1F1",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
        className="py-4 mb-5"
      >
        <Container>
          <div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: "0px 20px" }}
            >
              <Fade left delay={100}>
                <PrimaryText>Location</PrimaryText>
              </Fade>
              <Fade left delay={200}>
                <PrimaryText>Sales</PrimaryText>
              </Fade>
              <Fade left delay={300}>
                <PrimaryText>Support</PrimaryText>
              </Fade>
            </div>
            {supportDetails.map((item, i) => {
              return (
                <Fade left delay={100 * i} key={item.id}>
                  <TableHeader>
                    <SecondaryText margin="0px">{item.location}</SecondaryText>
                    <SecondaryText margin="0px">{item.sales}</SecondaryText>
                    <SecondaryText margin="0px">{item.support}</SecondaryText>
                  </TableHeader>
                </Fade>
              );
            })}
          </div>
        </Container>
      </div>
    </>
  );
};
