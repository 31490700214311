import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper";
import {
  EllipsisText,
  PrimaryText,
  SecondaryText,
  TitleText,
} from "../../styledComponent/styleComponent";
import "./slider.css";
import { Zoom } from "react-reveal";
import { Container } from "react-bootstrap";
import dev from "../../assets/images/dev.png";
import suresh from "../../assets/images/suresh.png";
import sushant from "../../assets/images/shushant.png";

export const Slider = () => {
  const testomonial = [
    {
      id: 0,
      aurthor: "Dev Raj Bhattarai",
      position: "React JS Developer",
      avatar: dev,
      quote:
        "The setup is fairly straightforward, and the learning curve is very manageable. It's really nice to have a  reasonable set of defaults for categories and account structure.In general, pivotalERP is the finest accounting software for start-ups and Large businesses. The following functionalities are included in this simple to use application: Assign Shortcuts, Create Quick Reports a UX that is easy to use, Software as a service (SaaS): It's simple to use and integrates well with the rest of the Pivotal Erp. Updates that arrive quickly and on a frequent basis.",
    },
    {
      id: 1,
      aurthor: "SuShant Ghimire",
      position: "UX/UI Developer",
      avatar: sushant,
      quote:
        "It was a great overall experience and was simple enough for a smaller business to implement while still having the horsepower. Having worked with a few erps, this is one of the cleanest UIs and has been a great experience with getting data out of it. The datacubes on the backend have been really good as well. Order allocation is clean and simple and has warehousing plugins that work well too.",
    },
    {
      id: 2,
      aurthor: "Suresh Paudel",
      position: "FullStack Developer",
      avatar: suresh,
      quote:
        "We like the development of new and added features over the years. It is also a fairly easy system to teach someone how to use and users can explore things on their own.",
    },
  ];
  return (
    <Container id="testomonial" className="mt-5">
      <SecondaryText>Testimonials</SecondaryText>
      <TitleText>Meet Our Client</TitleText>
      <Swiper
        effect={"coverflow"}
        grabCursor={false}
        centeredSlides={true}
        slidesPerView={"auto"}
        initialSlide={1}
        coverflowEffect={{
          rotate: 0,
          stretch: 190,
          depth: 50,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        slideToClickedSlide={true}
      >
        {testomonial.map((user) => (
          <SwiperSlide key={user.id}>
            <Zoom delay={0}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
              >
                <div className="testomonialDiv ">
                  <div
                    className="d-flex justify-content-center align-items-center position-absolute"
                    style={{
                      top: "-40px",
                    }}
                  >
                    <img
                      src={user.avatar}
                      alt=""
                      style={{
                        height: "80px",
                        width: "80px",
                        objectFit: "cover",
                        borderRadius: "80px",
                        border: "3px solid #fff",
                      }}
                    />
                  </div>
                  <div>
                    <PrimaryText className=" textColor">
                      {user.aurthor}
                    </PrimaryText>
                    <SecondaryText className="mb-3 textColor">
                      {user.position}
                    </SecondaryText>
                    <div className="px-4 position-relative">
                      <EllipsisText
                        lineClamp="10"
                        className="textColor quote text-start"
                      >
                        {user.quote}
                      </EllipsisText>
                    </div>
                  </div>
                </div>
              </div>
            </Zoom>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};
