import { Header } from "./HeaderSection";
import analysis from "../assets/svg/analysis.svg";
import girlWithCoin from "../assets/svg/girlWithCoin.svg";
import { Col, Container, Row } from "react-bootstrap";
import { SubContainer } from "./accountant/Accountant";
import analysisWithLens from "../assets/svg/analysisWithLens.svg";
import inventory from "../assets/svg/inventory.svg";
import {
  Image,
  ListItemCol,
  SecondaryText,
  TitleText,
} from "../styledComponent/styleComponent";
import { ListItem } from "./PlatformManagement";
import { PricingSolution } from "./Home";

export const Stocks = () => (
  <>
    <Header
      title="Stocks"
      subtitle="Maintain real-time stock control by keeping an eye on your orders and stock levels."
      image={analysis}
    />
    <Container className="mt-5">
      <SubContainer
        title="Make sure you have everything you need"
        subtitle="Keeping track of inventory in a small or midsized business can be difficult. By using PivotalERP you'll be able to manage your inventory efficiently, so that you're always in the know, as well as what you require."
        rightImage={true}
        image={girlWithCoin}
      />
      <Row className="m-0 mt-5 d-flex justify-content-between align-items-center">
        <Col md={5} className="ps-0">
          <Image src={analysisWithLens} alt="" maxHeight="300px" />
        </Col>
        <Col md={6} className="pe-0 mt-md-0 mt-4">
          <TitleText>Know your resources and seek to improve it</TitleText>
          <SecondaryText>
            Real-time stock level information and stock shortage signals in
            PivotalERP will help you stay up to date with your inventory.
          </SecondaryText>
          <ListItemCol>
            <ListItem
              title="See your current stock levels"
              subtitle="With PivotalERP, your inventory gets updated automatically as stock is brought in or exported. Understanding what is being sold and what's needed is easy."
            />
            <ListItem
              title="Providing real-time monitoring of stock prices"
              subtitle="Stock values change balance sheets as well. At regular intervals, the stock value will automatically change. ."
            />
            <ListItem
              title="Make sure orders are kept up to date"
              subtitle="Ensure that you keep track of what each supplier has given you. You can make your ordering process easier by centralizing supplier contact information."
            />
            <ListItem
              title="Inventory alerts at low levels"
              subtitle="Prepare for reorders in advance. A low inventory alert alerts you when it's time to reorder inventory. The purchase order will be converted into an account once inventory arrives so that you can promptly pay your suppliers."
            />
          </ListItemCol>
        </Col>
      </Row>
      <Row className="m-0 mt-5 d-flex justify-content-between align-items-center">
        <Col md={6} className="pe-0 mt-md-0 mt-4">
          <TitleText>Maintain an accurate inventory</TitleText>
          <SecondaryText>
            Use pictures, labels, and prices to classify your products.
            Automatically estimate the cost of each product using the "first in,
            first out" principle. Obtain tax information and sales data for the
            highest-selling products.
          </SecondaryText>
          <ListItemCol>
            <ListItem
              title="Don't try harder, but smarter"
              subtitle="Your invoices can be updated with stock items within minutes. You can update products and services for quicker processing."
            />
            <ListItem
              title="Prepare your lists before you go"
              subtitle="You can easily upload your vendor, customer, goods, and service list from Excel when you're first setting up PivotalERP, which allows you to see the current inventory levels and costs right away."
            />
          </ListItemCol>
        </Col>
        <Col md={5} className="ps-0">
          <Image src={inventory} alt="" maxHeight="300px" />
        </Col>
      </Row>
      <Row className="m-0 mt-5 d-flex justify-content-between align-items-center">
        <Col md={5} className="ps-0">
          <Image src={analysis} alt="" maxHeight="300px" />
        </Col>
        <Col md={6} className="pe-0 mt-md-0 mt-4">
          <TitleText>Examine what sells and what is worthwhile</TitleText>

          <ListItemCol>
            <ListItem
              title="Discover stock insights"
              subtitle="Get instant insight into revenue, taxes, and the most popular products. Using this inventory report to determine your position and to determine which products you should sell more of so that you can increase your income."
            />
            <ListItem
              title="Inventory figures are updated in real-time"
              subtitle="Your inventory values in your balance sheet are automatically altered by the accounting software."
            />
            <ListItem
              title="Inventories can be tracked according to items or dates"
              subtitle="View your inventory quantity for a specific period by sorting and monitoring taxable and tax-exempt merchandise."
            />
          </ListItemCol>
        </Col>
      </Row>
    </Container>
    {/* <PricingSolution title="Your business should choose a plan that's right for it" /> */}
    {/* <GradientBox title="See how PivotalERP Online can work for your business" /> */}
  </>
);
