import { Col, Container, Row } from "react-bootstrap";
import girlsAtTop from "../assets/svg/girlsAttop.svg";
import analytical from "../assets/svg/analytical.svg";
import salesTeam from "../assets/svg/salesTeam.svg";
import menWithLens from "../assets/svg/menWithLens.svg";
import menwithpen from "../assets/svg/menwithpen.svg";
import { ArrowBottom, WaveSvg } from "../assets/svg/IconSvg";
import { FaqSection } from "./FaqSection";
import { Blogs } from "./blogs/Blogs";
import {
  ArrowTipBox,
  Button,
  Circle,
  FilledLine,
  LinkText,
  PointerDiv,
  PricingButton,
  PricingSection,
  PrimaryText,
  SecondaryText,
  TitleText,
  UlList,
} from "../styledComponent/styleComponent";
import { Header } from "./HeaderSection";
import { useNavigate } from "react-router-dom";
import { Fade, Zoom } from "react-reveal";
import { Slider } from "./slider/Slider";

export const Home = ({ elRef }) => {
  const navigate = useNavigate();

  return (
    <>
      <Header
        title="Easy-to-use online accounting software for growing businesses"
        image={menwithpen}
      />

      <Container className="mt-5">
        <Row className="m-0 align-items-center  g-3">
          <Col md={6}>
            <Fade left delay={200}>
              <img src={girlsAtTop} alt="" width="85%" height="100%"></img>
            </Fade>
          </Col>
          <Col md={6}>
            <Fade up delay={100}>
              <TitleText>A single platform for managing everything</TitleText>
            </Fade>
            <Fade up delay={200}>
              <SecondaryText>
                Book-keeping can be done from a computer, laptop, tablet, or
                smartphone. You can create access credentials for your
                accountant and team to access your data online.
              </SecondaryText>
            </Fade>
            <Fade up delay={250}>
              <Button
                background="#009CEA"
                padding=" 5px 22px"
                className="mt-3"
                onClick={() => navigate("/platform-managing")}
              >
                <LinkText color="#fff">Learn More</LinkText>
              </Button>
            </Fade>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5">
        <Fade up delay={100}>
          <TitleText>
            Small & growing businesses must have effective accounting tools
          </TitleText>
        </Fade>

        <div className="d-flex flex-wrap position-relative mt-3 justify-content-center">
          <BoxContainer
            text="Your information is always at your fingertips with PivotalERP Online,
          so you can get the information you require at any time."
            title="Greetings nicely done"
          />
          <BoxContainer
            text="Keep your books accurate & up-to-date by integrating them with financial services and applications."
            gradient="linear-gradient(270deg, #5E9A3D 0.48%, #8CD465 116.61%)"
            background="#8CD465"
            title="Access to unlimited uptime"
          />
          <BoxContainer
            text="PivotalERP organizes & stores your expense receipts, so you can file every deduction at tax time."
            title="Every aspect should be acknowledged"
          />
          <BoxContainer
            gradient="linear-gradient(270deg, #5E9A3D 0.48%, #8CD465 116.61%)"
            background="#8CD465"
            text="You can always get help from PivotalERP, thanks to its free & unlimited support."
            title="We provide 24/7 support"
          />
        </div>
      </Container>
      <div
        style={{
          background:
            "linear-gradient(115.23deg, #0D97D6 -1.74%, #0D97D6 30.97%, #74B750 66.13%)",
        }}
        className="mt-5 py-4 d-flex align-items-center w-100"
      >
        <Container>
          <Row className="gy-4 d-flex justify-content-between align-items-center">
            <Col md={7} className=" order-2 order-md-1">
              <Fade up delay={100}>
                <TitleText color="#fff">
                  PivotalERP online allows you to manage your business more
                  effectively
                </TitleText>
              </Fade>
              <Fade up delay={200}>
                <Button
                  background="#fff"
                  padding=" 5px 22px"
                  className="mt-3"
                  onClick={() => navigate("/request-a-demo")}
                >
                  <LinkText color="#009CEA">Request a demo</LinkText>
                </Button>
              </Fade>
            </Col>
            <Col
              md={5}
              className=" order-1 order-md-2 d-flex justify-content-end"
              style={{
                minWidth: "300px",
              }}
            >
              <Fade right delay={100}>
                <img src={analytical} alt="" height="300px" width="100%"></img>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mt-5">
        <Row className="gy-4 d-flex justify-content-between align-items-center">
          <Col md={5} className="">
            <Fade left delay={100}>
              <img
                src={salesTeam}
                alt=""
                width="100%"
                height="100%"
                style={{ minWidth: "290px" }}
              ></img>
            </Fade>
          </Col>
          <Col md={6} className="">
            <Fade top delay={0}>
              <TitleText>
                Set your preferred conditions for your business
              </TitleText>
            </Fade>

            <div className="mt-3">
              <Fade top delay={100}>
                <PointerDiv>
                  <PrimaryText className="m-0">
                    The invoicing process
                  </PrimaryText>
                  <SecondaryText>
                    Easily create attractive invoices and send them instantly.
                    Increase your cash flow with personalized invoices and
                    automatic notifications.
                  </SecondaryText>
                </PointerDiv>
              </Fade>
              <Fade top delay={200}>
                <PointerDiv borderRadius="0px">
                  <PrimaryText className="m-0">Amounts incurred</PrimaryText>
                  <SecondaryText>
                    To keep you prepared for tax time, we will keep a record of
                    every receipt. Put anend to all those receipts in your
                    pocket.
                  </SecondaryText>
                </PointerDiv>
              </Fade>

              <Fade top delay={300}>
                <PointerDiv height="17px" borderRadius="0px">
                  <PrimaryText className="m-0">Detailed reports</PrimaryText>
                  <SecondaryText>
                    You can view a selection of useful reports to find out how
                    things are going. You can also build one with the details
                    that are important to you.
                  </SecondaryText>
                </PointerDiv>
              </Fade>

              <Fade top delay={350}>
                <Button
                  background="#009CEA"
                  padding=" 5px 22px"
                  className="mt-2 "
                  onClick={() => {
                    navigate("/preferred-conditions-business");
                    window.scrollTo(0, 0);
                  }}
                >
                  <LinkText color="#fff">Learn More</LinkText>
                </Button>
              </Fade>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <PricingSolution title="Pricing" /> */}
      <div
        className="mt-5 w-100 py-4"
        style={{
          background:
            "linear-gradient(115.23deg, #0D97D6 -1.74%, #0D97D6 30.97%, #74B750 66.13%)",
        }}
      >
        <Container>
          <Row className="gy-4 d-flex justify-content-between align-items-center w-100 g-2">
            <Col sm={6} md={6} className="order-2 order-sm-1">
              <Fade up delay={100}>
                <TitleText color="#fff">Why to choose us?</TitleText>
              </Fade>
              <Fade up delay={200}>
                <SecondaryText color="#fff">
                  Making informed decisions regarding finance is possible with
                  PivotalERP.
                </SecondaryText>
              </Fade>
              <Fade up delay={200}>
                <Button
                  background="#fff"
                  padding=" 5px 22px"
                  className="mt-3"
                  onClick={() => {
                    navigate("/why-chose-us");
                    window.scrollTo(0, 0);
                  }}
                >
                  <LinkText color="#009CEA">Explore more</LinkText>
                </Button>
              </Fade>
            </Col>
            <Col
              sm={6}
              md={6}
              className="d-flex justify-content-md-end justify-content-center align-items-center  order-1 order-sm-2"
            >
              <Fade right delay={100}>
                <img src={menWithLens} alt="" height="260px"></img>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
      <Blogs />

      <Slider />

      <FaqSection elRef={elRef} />
    </>
  );
};

export const BoxContainer = ({ text, title, gradient, background }) => (
  <>
    <div
      style={{
        height: "325px",
        width: "320px",
        position: "relative",
        marginTop: "1.5rem",
      }}
    >
      <Fade left delay={100}>
        <ArrowTipBox title={title} gradient={gradient} background={background}>
          <SecondaryText className="m-0 mt-5">{text}</SecondaryText>
          <ArrowBottom />
        </ArrowTipBox>
      </Fade>

      <div>
        <Circle background={background}></Circle>
        <FilledLine />
      </div>
    </div>
  </>
);

export const PricingSolution = ({ title }) => (
  <div className="m-0 mt-5 p-0 w-100 h-100 flex">
    <Fade up delay={100}>
      <TitleText>{title}</TitleText>
    </Fade>
    <Fade up delay={200}>
      <SecondaryText>
        PivotalERP has a solution for every business
      </SecondaryText>
    </Fade>

    <section>
      <div className="pricing pricing-palden">
        <PricingBoxContainer type="Standard" cost="15,000" />
        <PricingBoxContainer
          type="Professional"
          cost="25,000"
          background="#0077ad"
          className="pricing__item--featured"
        />
        <PricingBoxContainer type="Enterprise" cost="All feature packed" />
      </div>
    </section>
  </div>
);

const PricingBoxContainer = ({ type, cost, background, className }) => (
  <div
    className={`pricing-item ${className}`}
    data-animation="move-from-bottom"
    data-delay="item-1"
    style={{ minHeight: "497px" }}
  >
    <Zoom delay={200}>
      <PricingSection background={background}>
        <div
          style={{
            height: "calc(100% - 90px)",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="pricing-price">
            <span
              className={`pricing-currency ${
                type === "Enterprise" && "d-none"
              }`}
            >
              Rs
            </span>
            {cost}
            <span
              className={`pricing-period ${type === "Enterprise" && "d-none"}`}
            >
              / Year
            </span>
          </div>
          <h3 className="pricing-title">{type}</h3>
        </div>
        <WaveSvg waveHeight={className ? "waveHeight" : ""} />
      </PricingSection>
    </Zoom>
    <div className="px-4">
      <ul className="p-0 ps-3">
        <UlList className="ps-2 ">Full Features</UlList>
        <UlList className="ps-2 mt-3">Free support all year round</UlList>
        <UlList className="ps-2  mt-3">Free remote training</UlList>
        <UlList className="ps-2  mt-3">Unlimited storage</UlList>
        <UlList className="ps-2  mt-3">Unlimited users</UlList>
      </ul>
    </div>

    <PricingButton>Get Started</PricingButton>
  </div>
);
